import React, { useState, useEffect, useMemo, useContext, useRef } from 'react'
import Helmet from 'react-helmet'
import SVG from 'react-inlinesvg'
import userflow from 'userflow.js'
import {
  ExceptionHandler,
  applicationContext,
  ApplicationContextProvider,
  ModalContextProvider,
  DrawerContextProvider,
  SnackbarContextProvider,
  Tooltip,
  WithTooltip,
  useAuth,
  useOutsideClick,
  useModal,
  useApiGet,
  Link,
  varClass,
  navigateTo,
  navigateReload,
  isBlank,
  saveValue,
  loadValue,
  loadObjectValue,
  signupStateStorageKey,
  asideStateStorageKey,
  checkAccountActivity,
  checkAccountConversions,
} from '../shared'
import { getRequest } from '../../services/api'
import { connectGet } from '../../services/connect'
import {
  getCurrentUser,
  loginDemo,
  logoutDemo,
  isLoggedIn,
  isDemo,
} from '../../services/auth'
import { checkAdblock } from '../../services/adblock'

import '../../../static/stylesheets/bundle.scss'
import { navigate } from 'gatsby'

const isProduction = process.env.GATSBY_ENV === 'production'

const emailNotificationPaths = [
  '/email-notification/complete-update',
  '/email-notification/downgrade-plan',
  '/email-notification/invoice',
  '/email-notification/monthly-report',
  '/email-notification/plan-limit',
  '/email-notification/upgrade-plan',
]

const activeIfExact = (path: string) => {
  if (window.location.pathname === path) {
    return 'active'
  } else {
    return ''
  }
}

const activeIf = (path: string) => {
  if (window.location.pathname.includes(path)) {
    return 'active'
  } else {
    return ''
  }
}

const pageTitle = (path: string) => {
  if (path === '/') return 'Dashboard'
  if (path.startsWith('/insights/reports')) return 'Insights - All Statistics'
  if (path.startsWith('/insights/plugin')) return 'Insights - Plugin Statistics'
  if (path.startsWith('/insights/pages')) return 'Insights - Pages'
  if (path.startsWith('/discover/clicks')) return 'Discover - Network Clicks'
  if (path.startsWith('/discover/other')) return 'Discover - Clicks'
  if (path.startsWith('/insights/conversions')) return 'Insights - Conversions'
  if (path.startsWith('/insights/sources')) return 'Insights - Sources'
  if (path.startsWith('/discover/opportunities'))
    return 'Explore - Opportunities'
  if (path.startsWith('/merchants')) return 'Explore - Merchants'
  if (path.startsWith('/tools/urls')) return 'Links - Smart Links'
  if (path.startsWith('/discover/broken')) return 'Links - Broken Links'
  if (path.startsWith('/sites')) return 'Manage - My Sites'
  if (path.startsWith('/networks')) return 'Manage - Networks'
  if (path.startsWith('/partners')) return 'Manage - My Direct Partners'
  if (path.startsWith('/settings/profile')) return 'Settings - Profile'
  if (path.startsWith('/settings/billing')) return 'Settings - Billing'
  return 'Outbound'
}

export const Layout = (props) => {
  if (typeof window !== 'undefined') {
    if (
      emailNotificationPaths.some((path) =>
        window.location.pathname.startsWith(path)
      )
    ) {
      navigate(window.location.pathname)
      return null
    }
  }

  const authenticated = useAuth({
    redirectUnauthenticated: '/login',
  })

  useEffect(() => {
    if (!isBlank(loadObjectValue(signupStateStorageKey))) {
      navigateTo('/signup/')
    }
  }, [])

  useEffect(() => {
    checkAdblock()
  }, [])

  if (!authenticated) {
    return null
  }

  return (
    <ExceptionHandler>
      <ApplicationContextProvider>
        <ModalContextProvider>
          <DrawerContextProvider>
            <SnackbarContextProvider>
              <Content>{props.children}</Content>
            </SnackbarContextProvider>
          </DrawerContextProvider>
        </ModalContextProvider>
      </ApplicationContextProvider>
    </ExceptionHandler>
  )
}

const Content = (props) => {
  return (
    <>
      <Modals />
      <Head />
      <Aside />
      <DemoMode />
      <main>
        <MainBackground />
        {props.children}
      </main>
    </>
  )
}

const Head = (props) => {
  // const currentUser = getCurrentUser()

  // useEffect(() => {
  //   const callback = async () => {
  //     const channels = await getRequest('/channels/v1/list')
  //     const networks = await getRequest('/networks/v1/connections')

  //     const accountActive = await checkAccountActivity()

  //     try {
  //       userflow.init(process.env.USERFLOW_TOKEN)
  //       await userflow.identify(currentUser.user?.userUuid, {
  //         account: currentUser.account?.accountUuid,
  //         name: `${currentUser.user?.userFirstname} ${currentUser.user?.userLastname}`,
  //         email: currentUser.user?.userEmail,
  //         signed_up_at: currentUser.user?.userCreatedDatetime,
  //         channels: channels.data?.length,
  //         networks: networks.data?.length,
  //         valid_implementation: accountActive.toString(),
  //         demo_mode: isDemo.toString(),
  //       })
  //     } catch (error) {
  //       console.error(error)
  //     }
  //   }
  //   callback()
  // }, [])

  const bodyRef = useRef()
  useEffect(() => {
    const body = document.querySelector('body')
    if (!body) return

    const initialAsideState = loadValue(asideStateStorageKey)
    if (initialAsideState === 'collapse') {
      body.dataset.fullAside = 'false'
    }
  }, [bodyRef])

  const showAside = true
  // !location.pathname.match(/^\/advs\/\w+-\w+-\w+-\w+-\w+/) &&
  // !location.pathname.match(/^\/partners\/\w+-\w+-\w+-\w+-\w+/) &&
  // !location.pathname.match(/^\/channels\/\w+-\w+-\w+-\w+-\w+/)

  return (
    <Helmet>
      <title>{pageTitle(window.location.pathname)}</title>
      <meta name="viewport" content="width=768, initial-scale=1" />
      <body data-aside={String(showAside)} ref={bodyRef} />
    </Helmet>
  )
}

// const Header = (props) => {
//   const { headerTitle, headerContent } = useContext(applicationContext)
//
//   const breadcrumbs = Array.isArray(headerTitle) ? headerTitle : [headerTitle]
//
//   if (headerTitle === null && headerContent === null) {
//     return <header className="blank" />
//   }
//
//   return (
//     <header>
//       <div className="h1">
//         {breadcrumbs.map((title, index) => {
//           if (index < breadcrumbs.length - 1) {
//             return (
//               <span key={index}>
//                 {index === 0 ? (
//                   <a
//                     href={location.pathname.substring(
//                       0,
//                       location.pathname.indexOf('/', 1)
//                     )}
//                     className="link link-secondary text-bold"
//                   >
//                     {title}
//                   </a>
//                 ) : (
//                   <span className="text-light text-bold">{title}</span>
//                 )}
//                 <SVG src="/images/chevron-right.svg" className="m-x-3" />
//               </span>
//             )
//           } else {
//             return <span key={index}>{title}</span>
//           }
//         })}
//       </div>
//
//       <div className="header-menu">{headerContent}</div>
//     </header>
//   )
// }

// const Subheader = (props) => {
//   const { subheaderContent, subheaderPathname } = useContext(applicationContext)
//
//   if (subheaderContent && subheaderPathname === window.location.pathname) {
//     return <nav className="subheader">{subheaderContent}</nav>
//   } else {
//     return null
//   }
// }

const Aside = (props) => {
  const userMenuRef = useRef({ collapse: false })
  const [userMenuOpen, setUserMenuOpen] = useState(false)

  const showFull = () => {
    const aside = document.querySelector('aside')
    const body = document.querySelector('body')
    if (!aside || !body) return

    aside.classList.add('collapsing')
    body.dataset.fullAside = 'true'
    setTimeout(() => {
      aside.classList.remove('collapsing')
      aside.classList.remove('collapse')
      saveValue(asideStateStorageKey, 'full')
      userMenuRef.current.collapse = false
    }, 200)
  }

  const showSmall = () => {
    const aside = document.querySelector('aside')
    const body = document.querySelector('body')
    if (!aside || !body) return

    aside.classList.add('collapsing')
    body.dataset.fullAside = 'false'
    setTimeout(() => {
      aside.classList.remove('collapsing')
      aside.classList.add('collapse')
      saveValue(asideStateStorageKey, 'collapse')
      userMenuRef.current.collapse = true
    }, 200)
  }

  const asideRef = useRef()
  useEffect(() => {
    const aside = document.querySelector('aside')
    if (!aside) return

    const initialAsideState = loadValue(asideStateStorageKey)
    if (initialAsideState === 'collapse') {
      aside.classList.add('immediate', 'collapse')
      setTimeout(() => {
        aside.classList.remove('immediate')
      }, 50)
      userMenuRef.current.collapse = true
    }
  }, [asideRef])

  const currentUser = useMemo(getCurrentUser, [])
  const [hasPartners, setHasPartners] = useState(false)
  useEffect(() => {
    connectGet(`/publishers/${currentUser.account?.accountUuid}/check`).then(
      (response) => {
        if (response?.message === 'success') {
          setHasPartners(true)
        }
      }
    )
  }, [])

  const isRevshareAccount = currentUser?.user?.userAccountRevshareEnabled

  return (
    <>
      <aside ref={asideRef}>
        <div className="aside-full relative">
          <div className="top">
            {/*
            <SVG src="/images/logo-dark.svg" width={118} height={30} />
            */}
            <SVG src="/images/logo-white.svg" width={96} height={24} />
            <button className="link link-white full-toggle" onClick={showSmall}>
              <SVG src="/images/aside-full-on.svg" />
            </button>

            <ul className="nav">
              <li className={activeIfExact('/')}>
                <Link className="link" href="/">
                  <SVG src="/images/aside-overview.svg" />
                  <span>Dashboard</span>
                </Link>
              </li>

              <li className="subdiv">Insights</li>
              <li className={activeIf('/insights/reports')}>
                <Link className="link" href="/insights/reports">
                  <SVG src="/images/aside-statistics.svg" />
                  <span>All statistics</span>
                </Link>
              </li>
              {!isProduction && (
                <li className={activeIf('/insights/pages') + ' o-50'}>
                  <Link className="link" href="/insights/pages">
                    <SVG src="/images/aside-reports.svg" />
                    <span>Pages</span>
                  </Link>
                </li>
              )}
              {!isProduction && (
                <li className={activeIf('/insights/clicks') + ' o-50'}>
                  <Link className="link" href="/insights/clicks">
                    <SVG src="/images/aside-clicks.svg" />
                    <span>Clicks</span>
                  </Link>
                </li>
              )}
              {/*
              <li className={activeIf('/discover/clicks')}>
                <Link className="link" href="/discover/clicks">
                  <SVG src="/images/aside-clicks.svg" />
                  <span>Network clicks</span>
                </Link>
              </li>
              */}
              <li className={activeIf('/discover/other')}>
                <Link className="link" href="/discover/other">
                  <SVG src="/images/aside-discover.svg" />
                  <span>Discover clicks</span>
                </Link>
              </li>
              <li className={activeIf('/insights/plugin')}>
                <Link className="link" href="/insights/plugin">
                  <SVG src="/images/aside-statistics.svg" />
                  <span>Plugin statistics</span>
                </Link>
              </li>
              <li className={activeIf('/insights/conversions')}>
                <Link className="link" href="/insights/conversions">
                  <SVG src="/images/aside-conversions.svg" />
                  <span>Conversions</span>
                </Link>
              </li>
              {!isProduction && (
                <li className={activeIf('/insights/sources') + ' o-50'}>
                  <Link className="link" href="/insights/sources">
                    <SVG src="/images/aside-sources.svg" />
                    <span>Sources</span>
                  </Link>
                </li>
              )}

              <li className="subdiv">Explore</li>
              {!isRevshareAccount && (
                <li className={activeIf('/discover/opportunities')}>
                  <Link className="link" href="/discover/opportunities">
                    <SVG src="/images/aside-opportunities.svg" />
                    <span>Opportunities</span>
                  </Link>
                </li>
              )}
              <li className={activeIf('/merchants')}>
                <Link className="link" href="/merchants">
                  <SVG src="/images/aside-merchants.svg" />
                  <span>Merchants</span>
                </Link>
              </li>

              <li className="subdiv">Links</li>
              <li className={activeIf('/tools/urls')}>
                <Link className="link" href="/tools/urls">
                  <SVG src="/images/aside-smartlinks.svg" />
                  <span>Smart links</span>
                </Link>
              </li>
              {!isProduction && (
                <li className={activeIf('/discover/broken') + ' o-50'}>
                  <Link className="link" href="/discover/broken">
                    <SVG src="/images/aside-broken.svg" />
                    <span>Broken links scanner</span>
                  </Link>
                </li>
              )}

              <li className="subdiv">Manage</li>
              <li className={activeIf('/sites')}>
                <Link className="link" href="/sites">
                  <SVG src="/images/aside-sites.svg" />
                  <span>My sites</span>
                </Link>
              </li>
              <li className={activeIf('/networks')}>
                <Link className="link" href="/networks">
                  <SVG src="/images/aside-networks.svg" />
                  <span>Networks</span>
                </Link>
              </li>
              <li className={activeIf('/partners')}>
                <Link className="link" href="/partners">
                  <SVG src="/images/aside-partners.svg" />
                  <span>My direct partners</span>
                </Link>
              </li>
            </ul>
          </div>

          <div className="bottom">
            <UserMenu
              open={userMenuOpen}
              setOpen={setUserMenuOpen}
              userMenuRef={userMenuRef}
            />
          </div>
        </div>

        <div className="aside-small relative">
          <div className="top">
            {/*
            <SVG src="/images/logo-dark.svg" width={118} height={30} />
            */}
            <SVG src="/images/logo-small-white.svg" width={32} height={36} />

            <ul className="nav">
              <li
                className={activeIfExact('/')}
                data-tip="Dashboard"
                data-for="aside-tooltip"
              >
                <Link className="link" href="/">
                  <SVG src="/images/aside-overview.svg" />
                </Link>
              </li>

              <li
                className={activeIf('/insights/reports')}
                data-tip="All statistics"
                data-for="aside-tooltip"
              >
                <Link className="link" href="/insights/reports">
                  <SVG src="/images/aside-statistics.svg" />
                </Link>
              </li>
              {!isProduction && (
                <li
                  className={activeIf('/insights/pages') + ' o-50'}
                  data-tip="Pages"
                  data-for="aside-tooltip"
                >
                  <Link className="link" href="/insights/pages">
                    <SVG src="/images/aside-reports.svg" />
                  </Link>
                </li>
              )}
              {!isProduction && (
                <li
                  className={activeIf('/insights/clicks') + ' o-50'}
                  data-tip="Clicks"
                  data-for="aside-tooltip"
                >
                  <Link className="link" href="/insights/clicks">
                    <SVG src="/images/aside-clicks.svg" />
                  </Link>
                </li>
              )}
              {/*
              <li
                className={activeIf('/discover/clicks')}
                data-tip="Network clicks"
                data-for="aside-tooltip"
              >
                <Link className="link" href="/discover/clicks">
                  <SVG src="/images/aside-clicks.svg" />
                </Link>
              </li>
              */}
              <li
                className={activeIf('/discover/other')}
                data-tip="Discover clicks"
                data-for="aside-tooltip"
              >
                <Link className="link" href="/discover/other">
                  <SVG src="/images/aside-discover.svg" />
                </Link>
              </li>
              <li
                className={activeIf('/insights/conversions')}
                data-tip="Conversions"
                data-for="aside-tooltip"
              >
                <Link className="link" href="/insights/conversions">
                  <SVG src="/images/aside-conversions.svg" />
                </Link>
              </li>
              {!isProduction && (
                <li
                  className={activeIf('/insights/sources') + ' o-50'}
                  data-tip="Sources"
                  data-for="aside-tooltip"
                >
                  <Link className="link" href="/insights/sources">
                    <SVG src="/images/aside-sources.svg" />
                  </Link>
                </li>
              )}

              <li className="subdiv">Explore</li>
              {!isRevshareAccount && (
                <li
                  className={activeIf('/discover/opportunities')}
                  data-tip="Opportunities"
                  data-for="aside-tooltip"
                >
                  <Link className="link" href="/discover/opportunities">
                    <SVG src="/images/aside-opportunities.svg" />
                  </Link>
                </li>
              )}
              <li
                className={activeIf('/merchants')}
                data-tip="Merchants"
                data-for="aside-tooltip"
              >
                <Link className="link" href="/merchants">
                  <SVG src="/images/aside-merchants.svg" />
                </Link>
              </li>

              <li className="subdiv">Links</li>
              <li
                className={activeIf('/tools/urls')}
                data-tip="Smart links"
                data-for="aside-tooltip"
              >
                <Link className="link" href="/tools/urls">
                  <SVG src="/images/aside-smartlinks.svg" />
                </Link>
              </li>
              {!isProduction && (
                <li
                  className={activeIf('/discover/broken') + ' o-50'}
                  data-tip="Broken links scanner"
                  data-for="aside-tooltip"
                >
                  <Link className="link" href="/discover/broken">
                    <SVG src="/images/aside-broken.svg" />
                  </Link>
                </li>
              )}

              <li className="subdiv">Manage</li>
              <li
                className={activeIf('/sites')}
                data-tip="My sites"
                data-for="aside-tooltip"
              >
                <Link className="link" href="/sites">
                  <SVG src="/images/aside-sites.svg" />
                </Link>
              </li>
              <li className={activeIf('/networks')}>
                <Link
                  className="link"
                  href="/networks"
                  data-tip="Networks"
                  data-for="aside-tooltip"
                >
                  <SVG src="/images/aside-networks.svg" />
                </Link>
              </li>
              <li
                className={activeIf('/partners')}
                data-tip="My direct partners"
                data-for="aside-tooltip"
              >
                <Link className="link" href="/partners">
                  <SVG src="/images/aside-partners.svg" />
                </Link>
              </li>
              <li>
                <button
                  className="link link-white full-toggle"
                  onClick={showFull}
                >
                  <SVG src="/images/aside-full-off.svg" />
                </button>
              </li>
            </ul>
          </div>

          <div className="bottom">
            <UserMenu
              open={userMenuOpen}
              setOpen={setUserMenuOpen}
              userMenuRef={userMenuRef}
              collapse
            />
          </div>
        </div>
      </aside>

      <Tooltip
        id="aside-tooltip"
        type="light"
        place="right"
        rebuild={[userMenuOpen]}
      />
    </>
  )
}

const UserMenu = (props) => {
  const { open, setOpen, userMenuRef, collapse } = props

  const ref = useRef()

  const currentUser = getCurrentUser()

  useOutsideClick(ref, () => {
    if (userMenuRef.current.collapse === collapse) {
      setOpen(false)
    }
  })

  return (
    <div
      className={varClass({
        'user cursor-pointer': true,
        'open': open,
        'collapse': collapse,
      })}
      onClick={() => setOpen(!open)}
      ref={ref}
    >
      <div
        className={varClass({
          'user-info-container': true,
          'open': open,
        })}
      >
        <div className="user-avatar">
          <SVG src="/images/aside-user.svg" />
        </div>
        {/*
        <div className="user-info">
          <div className="user-name">{currentUser.user?.userFirstname}</div>
          <div className="user-email">{currentUser.user?.userEmail}</div>
        </div>
        */}
        <WithTooltip
          className="user-info"
          title={currentUser.user?.userEmail}
          text={currentUser.user?.userFirstname}
          tooltipId="user-menu-tooltip"
          forceShow
        />
      </div>

      {!!currentUser?.user?.userAccountPlan && (
        <div className="user-plan">{currentUser.user.userAccountPlan}</div>
      )}

      <Tooltip type="dark" place="top" delayShow={500} id="user-menu-tooltip" />

      <div
        className={varClass({
          'user-menu': true,
          'open': open,
        })}
      >
        <div className="user-menu-dots">
          <SVG src="/images/aside-user-menu.svg" />
        </div>
        <div
          className={varClass({
            'user-menu-nav': true,
            'open': open,
          })}
        >
          {collapse ? (
            <ul>
              <li
                className={activeIf('/settings/profile')}
                data-tip="Account"
                data-for="aside-tooltip"
              >
                <Link className="link" href="/settings/profile">
                  <SVG src="/images/aside-profile.svg" />
                </Link>
              </li>
              <li
                className={activeIf('/settings/billing')}
                data-tip="Subscription"
                data-for="aside-tooltip"
              >
                <Link className="link" href="/settings/billing">
                  <SVG src="/images/aside-billing.svg" />
                </Link>
              </li>
              <li className="divider" />
              <li
                className={activeIf('/help')}
                data-tip="Help"
                data-for="aside-tooltip"
              >
                <a
                  className="link"
                  href="https://docs.heylink.com"
                  target="_blank"
                >
                  <SVG src="/images/aside-help.svg" />
                </a>
              </li>
              <li
                className={activeIf('/logout')}
                data-tip="Logout"
                data-for="aside-tooltip"
              >
                <Link className="link" href="/logout">
                  <SVG src="/images/aside-logout.svg" />
                </Link>
              </li>
            </ul>
          ) : (
            <ul>
              <li className={activeIf('/settings/profile')}>
                <Link className="link" href="/settings/profile">
                  <SVG src="/images/aside-profile.svg" />
                  Account
                </Link>
              </li>
              <li className={activeIf('/settings/billing')}>
                <Link className="link" href="/settings/billing">
                  <SVG src="/images/aside-billing.svg" />
                  Subscription
                </Link>
              </li>
              <li className="divider" />
              <li className={activeIf('/help')}>
                <a
                  className="link"
                  href="https://docs.heylink.com"
                  target="_blank"
                >
                  <SVG src="/images/aside-help.svg" />
                  Help
                </a>
              </li>
              <li className={activeIf('/logout')}>
                <Link className="link" href="/logout">
                  <SVG src="/images/aside-logout.svg" />
                  Logout
                </Link>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

const MainBackground = (props) => {
  return (
    <div className="main-bg">
      <SVG src="/images/background-left.svg" />
      <SVG src="/images/background-right.svg" />
      <SVG src="/images/background-left.svg" />
      <SVG src="/images/background-right.svg" />
      <SVG src="/images/background-left.svg" />
      <SVG src="/images/background-right.svg" />
      <SVG src="/images/background-left.svg" />
      <SVG src="/images/background-right.svg" />
    </div>
  )
}

const Modals = (props) => {
  const { showModal } = useModal()

  return (
    <div className="modals-helper">
      <button
        id="demo-no-access-modal"
        className="btn btn-hidden"
        tabIndex={-1}
        onClick={() => {
          showModal(<DemoModeModal />)
        }}
      />
      <button
        id="adblock-modal"
        className="btn btn-hidden"
        tabIndex={-1}
        onClick={() => {
          showModal(<AdBlockModal />)
        }}
      />
    </div>
  )
}

const DemoMode = (props) => {
  const connections = useApiGet('/networks/v1/connections')

  const [noValidImplementation, setNoValidImplementation] = useState(false)

  const isLogged = useMemo(isLoggedIn, [])
  const isInDemo = useMemo(isDemo, [])
  const currentUser = useMemo(getCurrentUser, [])

  useEffect(() => {
    const callback = async () => {
      if (isInDemo) return

      const revshareEnabled = getCurrentUser()?.user?.userAccountRevshareEnabled
      if (revshareEnabled) {
        return
      }

      const accountActive = await checkAccountConversions()
      if (accountActive === false) {
        setNoValidImplementation(true)
      }
    }
    callback()
  }, [])

  const [active, setActive] = useState(isInDemo)
  const [activeText, setActiveText] = useState(active)

  const show =
    active ||
    isInDemo ||
    noValidImplementation ||
    (connections && isBlank(connections))
  if (!show) return null
  if (currentUser?.user?.userAccountRevshareEnabled) return null

  return (
    <div className="demo-mode-toggle">
      <div className="card p-6">
        <div className="row row-center row-space-between">
          <div className="row row-center row-fill row-nowrap">
            <div className="demo-icon">
              <SVG src="/images/settings/icon-medal-gradient.svg" />
            </div>
            <div>
              {activeText ? (
                <>
                  {isLogged ? (
                    <>
                      <div className="text-dark text-bigger text-bolder m-b-10">
                        You’re in demo mode.
                      </div>
                      <div className="text-bold">
                        Everything you see here is sample data and some features
                        are read-only.
                      </div>
                      <div className="text-bold">
                        Go to your dashboard and finish setting up to start
                        tracking your own data.
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-dark text-bigger text-bolder m-b-10">
                        You’re in demo mode.
                      </div>
                      <div className="text-bold">
                        Everything you see here is sample data and some features
                        are read-only.
                      </div>
                      <div className="text-bold">
                        Sign up to Heylink to create your affiliate dashboard
                        and start tracking your own data.
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="text-dark text-bigger text-bolder m-b-10">
                    Oh shoot, this looks a bit empty!
                  </div>
                  <div className="text-bold">
                    Your Heylink dashboard will display data after setup is
                    finished.
                  </div>
                  <div className="text-bold">
                    Change to demo mode to see sample data.
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="row row-center actions">
            {isLogged ? (
              <>
                <button
                  className="btn text-dark text-bolder vertical-middle text-nowrap"
                  onClick={async () => {
                    if (active) {
                      logoutDemo()
                    } else {
                      const response = await getRequest('/auth/v1/login/demo')
                      loginDemo(response.data)
                    }
                    const newActive = !active
                    setActive(newActive)
                    setActiveText(newActive)
                    navigateReload()
                  }}
                >
                  {active ? 'Turn off demo mode' : 'Turn on demo mode'}
                  <span
                    className={varClass({
                      'demo-toggle m-l-20': true,
                      'active': active,
                    })}
                  />
                </button>
                <button
                  id="demo-finish-setup-btn"
                  className="btn btn-gradient"
                  onClick={async () => {
                    setActive(false)
                    setActiveText(false)
                    logoutDemo()

                    navigateReload()
                    setTimeout(() => {
                      navigateTo(
                        '?userflow=abd9399c-5e70-4a90-9f48-8dea93640477'
                      )
                    }, 800)

                    // checkAdblock(true)
                  }}
                >
                  Finish setup
                </button>
              </>
            ) : (
              <>
                <button
                  className="btn text-dark text-bolder vertical-middle"
                  onClick={(event) => {
                    navigateTo(
                      event,
                      'https://heylink.com/?utm_source=direct&utm_medium=demo&utm_campaign=demo_account'
                    )
                  }}
                >
                  Learn more
                </button>
                <button
                  id="demo-finish-setup-btn"
                  className="btn btn-gradient"
                  onClick={() => {
                    setActive(false)
                    logoutDemo()
                  }}
                >
                  Sign up now
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const DemoModeModal = (props) => {
  const { hideModal } = useModal()

  return (
    <>
      <div className="card-body">
        <div className="text-center">
          <div className="text-dark text-bolder text-large">Demo mode</div>
          <div className="m-t-4">This action is not allowed in Demo mode</div>
          <button
            className="btn btn-dark btn-xl p-x-40 m-t-6"
            onClick={() => {
              hideModal()
            }}
          >
            OK
          </button>
        </div>
      </div>
    </>
  )
}

const AdBlockModal = (props) => {
  const { hideModal } = useModal()

  return (
    <>
      <div className="card-body">
        <div className="text-center">
          <SVG src="/images/error-illustration.svg" width={200} height={140} />
          <div className="text-dark text-bolder text-large m-t-8">
            Your Adblock is on
          </div>
          <div className="m-t-4">
            It seems you have an Adblock service on. That will affect some of
            the app functionalities.
          </div>
          <div className="m-t-3">
            To make full use of the app, turn off your Adblock.
          </div>
          <button
            className="btn btn-dark btn-xl p-x-40 m-t-6"
            onClick={() => {
              hideModal()
            }}
          >
            Close
          </button>
        </div>
      </div>
    </>
  )
}
