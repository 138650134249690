import React, { useState, useEffect, useMemo, useRef } from 'react'
import SVG from 'react-inlinesvg'
import ReactTooltip from 'react-tooltip'
import moment from 'moment'
import { toUnicode } from 'punycode'
import { uniq, uniqBy, groupBy, sortBy } from 'lodash'
import {
  TTimeout,
  IChannelsV1List,
  Loadable,
  Load,
  SmallLoadable,
  ConnectNetworkModal,
  Logo,
  Header,
  Commission,
  Search,
  Tooltip,
  WithTooltip,
  useApiGet,
  useApiPost,
  useOutsideClick,
  useSortableTable,
  useCounter,
  useArray,
  useModal,
  useSnackbar,
  formatCommission,
  formatStat,
  formatCurrencyReversed,
  calculateValues,
  navigateTo,
  navigateReload,
  newTableSort,
  plural,
  map,
  isBlank,
  toArray,
  varClass,
  INetworksV1Connections,
  DateInput,
} from '../shared'
import { deleteRequest, postRequest, putRequest } from '../../services'
import { Collapse } from '../shared/elements/Collapse'

const maxTableLength = 100

const graphColors = [
  '#0C74DC',
  '#33CEC5',
  '#3FAC73',
  '#DA4554',
  '#E06D02',
  '#A2E989',
  '#8552EA',
  '#EBB517',

  '#4F9EEC',
  '#9765FB',
  '#F6DE97',
]

const filterLabels = {
  'channelUuids': 'Site',
  'networkUuids': 'Network',
  'campaignUuids': 'Site',
}

export const findEventCampaign = (collection: any, event: any) =>
  collection.find(
    (item) =>
      item.campaignUuid === event.campaign.campaignUuid ||
      item.campaign?.campaignUuid === event.campaign.campaignUuid
  )

const campaignFilterWithLimit = (
  collection: any,
  search: string,
  filters: any,
  limit?: number
): [any, boolean] => {
  const filtered = collection.filter((item, index) => {
    if (search) {
      const query = search.toLowerCase()
      const values = [
        item.campaign.campaignName,
        item.campaign.campaignId,
        item.campaign.campaignUrls?.join(' '),
        item.network.networkName,
        item.channels.map((channel) => channel.channelName)?.join(' '),
      ]
        .filter((value) => value)
        .map((value) => value.toLowerCase())
      if (values.every((value) => !value.includes(query))) return false
    }
    for (const filter of filters) {
      if (filter.value) {
        switch (filter.key) {
          case 'channelUuids':
            if (
              !item.channels.find((channel) =>
                filter.value.split(', ').includes(channel.channelUuid)
              )
            )
              return false
            break
          case 'networkUuids':
            if (!filter.value.split(', ').includes(item.network.networkUuid))
              return false
            break
          default:
            if (
              !filter.value
                .split(', ')
                .includes(item[filter.key.replace('Uuids', 'Uuid')])
            )
              return false
            break
        }
      }
    }
    return true
  })
  if (limit && filtered.length > limit) {
    return [
      filtered.filter((item, index) => {
        if (limit && index >= limit) return false
        return true
      }),
      true,
    ]
  } else {
    return [filtered, false]
  }
}

const campaignSort = newTableSort((a: any, b: any, key: string) => {
  if (key === 'networkName') {
    return [
      a.network.networkName + a.campaign.campaignId,
      b.network.networkName + b.campaign.campaignId,
    ]
  }
  if (key === 'performance') {
    if (a.performance.revenue && b.performance.revenue) {
      return [a.performance.revenue, b.performance.revenue]
    }
    // if (a.performance.conversionsCount && b.performance.conversionsCount) {
    //   return [a.performance.conversionsCount, b.performance.conversionsCount]
    // }
    return [a.performance.clicksCount, b.performance.clicksCount]
  }
  if (key === 'campaignCommission') {
    return [
      a.commissions.commissionFlat || a.commissions.commissionPercentage,
      b.commissions.commissionFlat || b.commissions.commissionPercentage,
    ]
  }
})

const trafficSort = newTableSort()

export const Merchant2 = (props) => {
  const { advertiserUuid } = props
  const [campaignReloadRef, reloadCampaign] = useCounter()
  const [search, setSearch] = useState('')
  const [filters, setFilters] = useState([
    {
      key: 'channelUuids',
      value: '',
      label: '',
    },
    {
      key: 'networkUuids',
      value: '',
      label: '',
    },
  ])
  const filterReset = filters
    .filter((filter) => filter.value)
    .map((filter) => filter.value)
    .join(',')

  const channels = useApiGet<IChannelsV1List[]>('/channels/v1/list')
  const networks = useApiGet('/networks/v1/list')
  const networkDetails = useApiGet('/networks/v1/networks')

  const data = useApiGet(`/advs/v1/advs/${advertiserUuid}`)
  const campaigns = useApiPost<any>(
    `/advs/v2/advs/${advertiserUuid}/camps`,
    {},
    [campaignReloadRef]
  )
  const traffic = useApiPost(
    '/insights/v1/statistics',
    {
      groupBy: 'page',
      fromDate: moment().subtract(30, 'days').toDate(),
      toDate: new Date(),
      filters: {
        advertiserUuids: [advertiserUuid],
        ...filters.reduce((result, filter) => {
          if (filter.value) result[filter.key] = filter.value.split(', ')
          return result
        }, {}),
      },
    },
    [advertiserUuid, filterReset]
  )

  const channelsMap = useMemo(() => {
    if (channels) {
      return channels.reduce((result, channel) => {
        result[channel.channelUuid] = channel
        return result
      }, {})
    } else {
      return {}
    }
  }, [channels])

  return (
    <>
      <Header backPath="/merchants" />
      <Loadable data={data && campaigns && channels && networks}>
        <Content
          data={data}
          campaigns={toArray(campaigns)}
          {...{
            channels,
            channelsMap,
            networks,
            networkDetails,
            advertiserUuid,
            traffic,
            search,
            setSearch,
            filters,
            setFilters,
            reloadCampaign,
          }}
        />
      </Loadable>
    </>
  )
}

const Content = (props) => {
  const {
    data,
    campaigns,
    comments,
    channels,
    channelsMap,
    networks,
    networkDetails,
    advertiserUuid,
    traffic,
    search,
    setSearch,
    filters,
    setFilters,
    reloadCampaign,
  } = props

  const { advertiser, performance } = data

  const [tab, setTab] = useState('')

  const [connectedNetworks, activeCampaigns, otherCampaigns, closedCampaigns] =
    useMemo(() => {
      if (!campaigns?.length) {
        return [[], [], [], []]
      }

      const connectedNetworks = uniqBy(
        campaigns
          .filter((campaign) => campaign.isConnected)
          .map((campaign) => campaign.network),
        (network: any) => network.networkUuid
      )
      const activeCampaigns = campaigns.filter(
        (campaign) =>
          campaign.campaign.campaignIsActive &&
          (campaign.performance.clicksCount ||
            campaign.performance.conversionsCount ||
            campaign.performance.revenue)
      )
      const otherCampaigns = campaigns.filter(
        (campaign) =>
          campaign.campaign.campaignIsActive &&
          !(
            campaign.performance.clicksCount ||
            campaign.performance.conversionsCount ||
            campaign.performance.revenue
          )
      )
      const closedCampaigns = campaigns.filter(
        (campaign) => !campaign.campaign.campaignIsActive
      )

      return [
        connectedNetworks,
        activeCampaigns,
        otherCampaigns,
        closedCampaigns,
      ]
    }, [campaigns])

  const { showModal } = useModal()
  const { showSnackbar } = useSnackbar()

  const histories = useApiPost(
    `/advs/v1/advs/${advertiser.advertiserUuid}/camps/histories`,
    {
      fromDate: new Date(
        advertiser.advertiserCreatedDatetime.replace(' ', 'T')
      ),
      toDate: new Date(),
    }
  )

  return (
    <div className="advertiser-show-content">
      <div className="advertiser-info">
        <div className="card">
          <div className="card-body p-t-10 p-b-1">
            <div className="row row-center row-fill">
              <Logo
                src={advertiser.advertiserFaviconUrl}
                width={32}
                height={32}
                className="m-r-3"
                bordered
              />
              <span className="text-dark text-big text-bolder m-r-2">
                {advertiser.advertiserDomain}
              </span>
              <a
                href={`https://${advertiser.advertiserDomain}`}
                target="_blank"
                rel="noreferrer"
                className="link link-lighter m-t-05"
              >
                <SVG src="/images/icon-outside.svg" />
              </a>
            </div>
            <div className="m-t-5">
              <div className="row row-space-between">
                <div className="text-light text-small text-bold">
                  Date added:
                </div>
                <div className="text-dark">
                  {advertiser.advertiserCreatedDatetime.split(' ')[0]}
                </div>
              </div>
            </div>
            {advertiser.categoryName && (
              <div className="m-t-2">
                <div className="row row-space-between">
                  <span className="text-light text-small text-bold">
                    Category:
                  </span>
                  {advertiser.categoryName}
                </div>
              </div>
            )}
            {connectedNetworks.length > 0 && (
              <div className="m-t-2">
                <div className="row row-space-between">
                  <span className="text-light text-small text-bold">
                    Found on:
                  </span>
                  <div>
                    {map(connectedNetworks, (network) => (
                      <span
                        key={network.networkUuid}
                        className="badge badge-hoverable text-smaller text-nowrap m-05"
                        onClick={(event) => {
                          navigateTo(event, `/networks/${network.networkUuid}`)
                        }}
                      >
                        {network.networkName}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            )}

            <div className="card-divider" />

            <div className="text-dark text-bold m-t-6">Performance 30 days</div>
            <div className="row row-center row-space-between m-t-4">
              <span className="text-light text-small text-bold">Clicks:</span>
              <span className="value-badge value-badge-clickCount">
                {formatStat(performance.clicksCount)}
              </span>
            </div>
            <div className="row row-center row-space-between m-t-4">
              <span className="text-light text-small text-bold">
                Conversions:
              </span>
              <span className="value-badge value-badge-conversionCount">
                {formatStat(performance.conversionsCount)}
              </span>
            </div>
            <div className="row row-center row-space-between m-t-4">
              <span className="text-light text-small text-bold">Revenue:</span>
              <span className="value-badge value-badge-conversionCommission">
                {formatCurrencyReversed(
                  performance.revenue,
                  performance.currencyCode || 'DKK'
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="card m-t-20">
          <div className="card-body p-t-0 p-b-1">
            <div className="text-dark text-big text-bolder m-b-4">Notes</div>
            <Comments advertiserUuid={advertiserUuid} />
          </div>
        </div>
      </div>

      <div className="advertiser-data">
        <div className="card p-b-0 m-b-20">
          {/*<div className="advertiser-tab-select">
            {map(
              {
                '': 'Overview',
                'traffic': 'Traffic',
              },
              (value, label) => (
                <button
                  key={value}
                  className={varClass({
                    'link': true,
                    'active': tab === value,
                  })}
                  onClick={() => setTab(value)}
                >
                  {label}
                </button>
              )
            )}
          </div>*/}

          <div className="card-body p-t-10">
            <div className="vertical-middle m-b-7">
              <CampaignSearch {...{ search, setSearch }} />
              <CampaignsFilter
                {...{ filters, setFilters, channels, networks }}
              />
            </div>

            {tab === '' && (
              <>
                <Table
                  title="Campaigns with traffic the last 30 days"
                  campaigns={activeCampaigns}
                  allCampaigns={[...activeCampaigns, ...otherCampaigns]}
                  advertiserUuid={advertiserUuid}
                  networkDetails={networkDetails}
                  search={search}
                  filters={filters}
                  channels={channels}
                  channelsMap={channelsMap}
                  showModal={showModal}
                  showSnackbar={showSnackbar}
                  reloadCampaign={reloadCampaign}
                />
                <Table
                  title="Other campaigns"
                  campaigns={otherCampaigns}
                  allCampaigns={[...activeCampaigns, ...otherCampaigns]}
                  networkDetails={networkDetails}
                  advertiserUuid={advertiserUuid}
                  search={search}
                  filters={filters}
                  channels={channels}
                  channelsMap={channelsMap}
                  showModal={showModal}
                  showSnackbar={showSnackbar}
                  reloadCampaign={reloadCampaign}
                  className="m-t-40"
                />
                <ClosedCampaignTable
                  title="Closed campaigns"
                  campaigns={closedCampaigns}
                  networkDetails={networkDetails}
                  search={search}
                  filters={filters}
                  channels={channels}
                  channelsMap={channelsMap}
                  showModal={showModal}
                  showSnackbar={showSnackbar}
                  reloadCampaign={reloadCampaign}
                  className="m-t-40"
                />
              </>
            )}

            {tab === 'traffic' && (
              <>
                <SmallLoadable loaded={traffic}>
                  <TrafficTable
                    title="Campaigns with traffic the last 30 days"
                    data={traffic}
                    channels={channels}
                  />
                </SmallLoadable>
              </>
            )}
          </div>
        </div>

        <div className="card p-b-0">
          <div className="card-body p-t-10">
            <SmallLoadable loaded={histories} height={80} className="m-b-60">
              <CampaignHistory
                data={histories}
                {...{ campaigns, channels, networks }}
              />
            </SmallLoadable>
          </div>
        </div>
      </div>
    </div>
  )
}

const Comments = (props) => {
  const { advertiserUuid } = props

  const [reloadRef, reload] = useCounter()
  const comments = useApiGet<any[]>(`/advs/v1/comments/${advertiserUuid}`, {}, [
    reloadRef,
  ])

  const [submitting, setSubmitting] = useState(false)
  const [shownComments, setShownComments] = useState([])
  const [newComment, setNewComment] = useState('')
  const [editCommentUuid, setEditCommentUuid] = useState('')

  useEffect(() => {
    if (comments) {
      setShownComments(toArray(comments))
    }
  }, [comments])

  const onSubmit = async () => {
    if (!newComment.trim()) return
    if (submitting) return
    setSubmitting(true)

    if (editCommentUuid) {
      await putRequest(
        `/advs/v1/comments/${advertiserUuid}/${editCommentUuid}`,
        {
          content: newComment,
        }
      )
      const newShownComments = [...shownComments]
      const editedIndex = newShownComments.findIndex(
        (comment) => comment.commentUuid === editCommentUuid
      )
      if (editedIndex > -1) {
        newShownComments[editedIndex].commentText = newComment
        newShownComments[editedIndex].commentUpdatedDatetime =
          moment().toString()
        setShownComments(newShownComments)
      }
    } else {
      await postRequest(`/advs/v1/comments/${advertiserUuid}`, {
        content: newComment,
      })
      setShownComments([
        {
          commentUuid: '',
          commentText: newComment,
          commentAuthor: '',
          commentUpdatedDatetime: moment().toString(),
        },
        ...shownComments,
      ])
    }

    setNewComment('')
    setEditCommentUuid('')
    reload()
    setSubmitting(false)
  }

  const onDelete = async (uuid: string) => {
    if (submitting) return
    setSubmitting(true)
    await deleteRequest(`/advs/v1/comments/${advertiserUuid}/${uuid}`)
    setShownComments(
      shownComments.filter((comment) => comment.commentUuid !== uuid)
    )
    reload()
    setSubmitting(false)
  }

  return (
    <div>
      <Loadable
        data={comments}
        loaded={shownComments.length}
        placeholder={
          <div className="m-b-4">
            <Load width={200} height={17} className="m-b-2" />
            <Load width={160} height={16} className="m-b-2" />
          </div>
        }
      >
        {map(shownComments, (comment) => (
          <div key={comment.commentUuid} className="advertiser-note m-b-4">
            <div className="m-b-2">
              {comment.commentText
                .split('\n')
                .map((line: string, index: number) => (
                  <div key={comment.commentUuid + index}>{line}</div>
                ))}
            </div>
            <div className="row row-center row-space-between text-light text-small">
              <i>{comment.commentAuthor}</i>
              <div>
                {moment(comment.commentUpdatedDatetime).format(
                  'YYYY-MM-DD HH:mm'
                )}
              </div>
            </div>
            {!!comment.commentUuid && (
              <div className="note-actions">
                <button
                  className="link btn-borderless p-x-1"
                  onClick={(event) => {
                    setEditCommentUuid(comment.commentUuid)
                    setNewComment(comment.commentText)
                    const buttonEl = event.target as HTMLButtonElement
                    buttonEl
                      .closest('.advertiser-note')
                      .parentElement.querySelector('textarea')
                      .focus()
                  }}
                >
                  <SVG src="/images/icon-edit.svg" width={16} />
                </button>
                <button
                  className="link btn-borderless link-danger p-x-1"
                  onClick={() => onDelete(comment.commentUuid)}
                >
                  <SVG src="/images/icon-trash.svg" width={16} />
                </button>
              </div>
            )}
          </div>
        ))}
      </Loadable>

      <div
        className={varClass({
          'control w-100 m-b-2': true,
          'm-t-4': shownComments?.length || comments === null,
        })}
      >
        <textarea
          className="w-100"
          value={newComment}
          onChange={(event) => setNewComment(event.target.value)}
        />
      </div>
      <div className="row row-center row-end">
        <div>
          {submitting && (
            <SmallLoadable loaded={false} style={{ width: 16, height: 16 }} />
          )}
        </div>
        {editCommentUuid ? (
          <>
            <button
              className="btn btn-md"
              onClick={() => {
                setEditCommentUuid('')
                setNewComment('')
              }}
              disabled={submitting}
            >
              Cancel
            </button>
            <button
              className="btn btn-md"
              onClick={onSubmit}
              disabled={submitting}
            >
              Update
            </button>
          </>
        ) : (
          <button
            className="btn btn-md"
            onClick={onSubmit}
            disabled={submitting}
          >
            Save
          </button>
        )}
      </div>
    </div>
  )
}

const CampaignSearch = (props) => {
  const { search, setSearch } = props
  const [value, setValue] = useState(search)

  const valueRef = useRef<TTimeout>()
  useEffect(() => {
    if (valueRef.current) {
      clearTimeout(valueRef.current)
    }
    valueRef.current = setTimeout(() => {
      setSearch(value)
      valueRef.current = null
    }, 650)
  }, [value])

  return (
    <>
      <Search
        value={value}
        setValue={setValue}
        onSubmit={() => {
          setSearch(value)
        }}
        placeholder="Search campaigns"
        className="search w-30 m-r-15"
        inputClassName="w-100 p-y-15 p-r-8 campaign-search-input"
        highlight={!!value}
      />
      <button
        className={varClass({
          'link link-new-lighter': true,
          'o-0 unclickable': !value,
          'o-100': value,
        })}
        style={{
          position: 'relative',
          top: 2,
          left: -36,
          paddingTop: 4,
          marginTop: -4,
          marginRight: -32,
        }}
        onClick={() => {
          setValue('')
          setSearch('')
        }}
      >
        <SVG src="/images/icon-times.svg" />
      </button>
    </>
  )
}

export const CampaignsFilter = (props) => {
  const { filters, setFilters, channels, networks } = props

  return (
    <div
      className={varClass({
        'subnav-filters': true,
      })}
    >
      {map(filters, (item, index) => (
        <SubnavSelect
          key={item.key}
          index={index}
          {...{
            item,
            channels,
            networks,
          }}
          buttonClassName={`filter-button-${item.key}`}
          selected={item.value.split(', ')}
          onSelect={(value, label) => {
            const newFilters = [...filters]
            const filter = newFilters.find((filter) => filter.key === item.key)
            if (filter.value) {
              const splitValue = filter.value.split(', ')
              const splitLabel = filter.label.split(', ')
              const existValue = splitValue.find((v) => v === value)
              if (existValue) {
                filter.value = splitValue.filter((v) => v !== value).join(', ')
                filter.label = splitLabel.filter((l) => l !== label).join(', ')
              } else {
                filter.value += `, ${value}`
                filter.label += `, ${label}`
              }
            } else {
              filter.value = value
              filter.label = label
            }
            setFilters(newFilters)
          }}
          onRemove={() => {
            const newFilters = [...filters]
            newFilters[index].value = ''
            newFilters[index].label = ''
            setFilters(newFilters)
          }}
        />
      ))}
    </div>
  )
}

const SubnavSelect = (props) => {
  const {
    wrapperClassName,
    buttonClassName,
    menuClassName,
    item,
    selected,
    onSelect,
    onRemove,
    channels,
    networks,
    trackers,
  } = props

  const [open, setOpen] = useState(false)
  const ref = useRef()
  useOutsideClick(ref, () => {
    setOpen(false)
  })

  const [search, setSearch] = useState('')
  const options = useMemo(() => {
    switch (item.key) {
      case 'channelUuids':
        if (!channels || !Array.isArray(channels)) return {}
        return channels?.reduce((result, channel) => {
          if (channel.channelUuid)
            result[channel.channelUuid] =
              channel.channelName || channel.channelDomain
          return result
        }, {})
      case 'networkUuids':
        if (!networks || !Array.isArray(networks)) return {}
        return networks?.reduce((result, network) => {
          if (network.networkUuid)
            result[network.networkUuid] = network.networkName
          return result
        }, {})
      case 'campaignUuids':
        if (!trackers || !Array.isArray(trackers)) return {}
        return trackers?.reduce((result, tracker) => {
          if (tracker.trackerUuid)
            result[tracker.trackerUuid] = tracker.trackerUrl
          return result
        }, {})
      default:
        return {}
    }
  }, [item.key])
  const showSearch = Object.keys(options).length > 1
  const shownOptions = useMemo(() => {
    if (search) {
      return Object.keys(options).reduce((result, option) => {
        if (options[option].toLowerCase().indexOf(search.toLowerCase()) !== -1)
          result[option] = options[option]
        return result
      }, {})
    } else {
      return options
    }
  }, [options, search])

  return (
    <div
      className={varClass({
        'subnav-filter': true,
        [wrapperClassName || '']: !!wrapperClassName,
      })}
      ref={ref}
    >
      <button
        className={varClass({
          'filter-button text-primary m-r-15 merchant-filter-button': true,
          'active': open,
          [buttonClassName || '']: !!buttonClassName,
        })}
        onClick={() => setOpen(!open)}
      >
        <>
          {filterLabels[item.key]}:{' '}
          <b className="m-l-1">{item.label || 'All'}</b>
        </>
        <SVG
          src="/images/chevron-down.svg"
          className={varClass({
            'm-l-1': true,
            'rotate-180': open,
          })}
        />
      </button>
      <div
        className={varClass({
          'submenu': true,
          'shown': open,
          [menuClassName || '']: !!menuClassName,
        })}
      >
        {showSearch && (
          <div className="control control-focusless">
            <SVG src="/images/icon-search.svg" />
            <input
              type="text"
              placeholder={`Find ${filterLabels[item.key].toLowerCase()}`}
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
        )}
        <ul>
          {map(shownOptions, (value) => (
            <li
              key={value}
              title={toUnicode(options[value])}
              className={varClass({
                'selected': selected.includes(value),
              })}
              onClick={() => {
                onSelect(value, toUnicode(options[value]))
                setOpen(false)
              }}
            >
              {toUnicode(options[value])}
            </li>
          ))}
          {isBlank(shownOptions) && (
            <li className="text-light text-bold text-center inactive">
              Nothing found
            </li>
          )}
        </ul>
        {selected?.length && (
          <button
            className="remove-btn link"
            onClick={() => {
              onRemove()
              setOpen(false)
            }}
          >
            <SVG src="/images/icon-trash.svg" className="m-r-2" />
            Remove filter
          </button>
        )}
      </div>
    </div>
  )
}

const checkDate = (newest, oldest, subtractValue, subtractType) => {
  const date = moment().subtract(subtractValue, subtractType)
  return newest > date && oldest < date
}

const CampaignsDateLimitPicker = (props) => {
  const { dateLimit, setDateLimit } = props

  const options = useMemo(() => {
    return ['1w', '1m', '3m', '12m']
  }, [])

  return (
    <div className="advertiser-campaign-date-limit">
      <ul>
        {map(options, (option) => (
          <li
            key={option}
            className={varClass({
              'selected cursor-default': dateLimit === option,
            })}
            onClick={() => {
              if (dateLimit !== option) {
                setDateLimit(option)
              }
            }}
          >
            {option.toUpperCase()}
          </li>
        ))}
        <li
          className={varClass({
            'selected cursor-default': dateLimit === '',
          })}
          onClick={() => {
            if (dateLimit !== '') {
              setDateLimit('')
            }
          }}
        >
          All time
        </li>
      </ul>
    </div>
  )
}

const Table = (props) => {
  const {
    title,
    campaigns,
    allCampaigns,
    advertiserUuid,
    networkDetails,
    search,
    filters,
    channels,
    channelsMap,
    showModal,
    showSnackbar,
    className,
    reloadCampaign,
  } = props

  const { sort, dir, toggleSort } = useSortableTable({
    sort: 'networkName',
    dir: 'asc',
  })

  const headProps = { sort, dir, toggleSort }

  const [shownCampaigns, hasMore] = useMemo(() => {
    const [filtered, hasMore] = campaignFilterWithLimit(
      campaigns,
      search,
      filters,
      maxTableLength
    )
    const sorted = campaignSort(filtered, sort, dir)
    return [sorted, hasMore]
  }, [campaigns, search, filters, sort, dir])

  const showCreatorIds = campaigns.some(
    (campaign) =>
      campaign.campaign.campaignIsCreatorOnly ||
      campaign.trackers.some((tracker) => tracker.trackerCreatorIds.length)
  )
  const showTrafficPercentage = campaigns.some(
    (campaign) =>
      campaign.campaign.campaignIsSplitTraffic ||
      campaign.campaign.campaignIsPartialTraffic
  )

  return (
    <div className={className || ''}>
      <div className="text-dark text-bold m-b-5">{title}</div>
      <div className="card-body-fill relative">
        <div className="table-container-overflow">
          <table className="table table-bodered table-sortable advertiser-campaigns-table">
            <thead className="table-sticky">
              <tr>
                <TableHead
                  value="networkName"
                  label="Campaign"
                  {...headProps}
                />
                <TableHead
                  value="performance"
                  label="Performance 30 days"
                  desc
                  {...headProps}
                />
                <TableHead
                  value="campaignCommission"
                  label="Commission"
                  desc
                  {...headProps}
                />
                <th className="text-nowrap">Sites</th>
                {showCreatorIds && <th className="text-nowrap">Creators</th>}
                {showTrafficPercentage && (
                  <th className="text-nowrap">Traffic</th>
                )}
                <th className="text-nowrap">Actions</th>
              </tr>
            </thead>
            <tbody>
              {map(shownCampaigns, (campaign, index) => (
                <TableItem
                  key={`${campaign.campaign.campaignUuid}_${index}`}
                  {...{
                    campaign,
                    allCampaigns,
                    advertiserUuid,
                    networkDetails,
                    channels,
                    channelsMap,
                    sort,
                    showCreatorIds,
                    showTrafficPercentage,
                    showModal,
                    showSnackbar,
                    reloadCampaign,
                  }}
                  isLast={index === shownCampaigns.length - 1}
                />
              ))}
              {isBlank(shownCampaigns) ? (
                <tr>
                  <td
                    colSpan={10}
                    className="text-center text-light text-bolder"
                  >
                    Nothing found
                  </td>
                </tr>
              ) : hasMore ? (
                <tr>
                  <td
                    colSpan={10}
                    className="text-center text-light text-bolder"
                  >
                    More campaigns available, please use search campaign field
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>

      <Tooltip
        id="advertiser-show-opportunity-tooltip"
        type="dark"
        effect="solid"
        place="bottom"
        rebuild={[shownCampaigns]}
      />
    </div>
  )
}

const TableHead = (props) => {
  const { value, label, desc, sort, dir, toggleSort } = props

  return (
    <th
      className={varClass({
        'sort-highlight': sort === value,
      })}
      onClick={toggleSort(value, desc ? 'desc' : 'asc')}
    >
      <span className="column-label text-nowrap">
        {label}
        {sort === value ? (
          <>
            {dir === 'desc' ? (
              <SVG src="/images/insights/caret-down.svg" className="m-l-1" />
            ) : (
              <SVG src="/images/insights/caret-up.svg" className="m-l-1" />
            )}
          </>
        ) : (
          <SVG src="/images/insights/caret.svg" className="m-l-1" />
        )}
      </span>
    </th>
  )
}

const TableItem = (props) => {
  const {
    campaign,
    allCampaigns,
    advertiserUuid,
    networkDetails,
    channels,
    channelsMap,
    sort,
    showCreatorIds,
    showTrafficPercentage,
    showModal,
    showSnackbar,
    isLast,
    reloadCampaign,
  } = props

  const [expandedChannels, setExpandedChannels] = useState(false)
  const [expandedTrackers, setExpandedTrackers] = useState(false)

  const shownExpandedTrackers = useMemo(() => {
    const groups = groupBy(campaign.trackers, (tracker) =>
      [
        tracker.trackerUrlMatch,
        tracker.trackerCommissionType,
        tracker.trackerCommissionPercentage,
        tracker.trackerCommissionFlat,
        tracker.trackerIsActive,
        tracker.trackerIsPriority,
        tracker.trackerIsFallback,
        tracker.trackerIsExcluded,
        tracker.trackerIsSplitTraffic,
        tracker.trackerIsPartialTraffic,
        tracker.trackerTrafficChance,
      ].join(':')
    )
    return Object.values(groups).map((trackers) => ({
      trackerUuids: trackers.map((tracker) => tracker.trackerUuid).sort(),
      trackerChannelUuids: uniq(
        trackers.map((tracker) => tracker.trackerChannelUuid)
      ).sort(),
      trackerUrlMatch: trackers[0].trackerUrlMatch,
      trackerCommissionType: trackers[0].trackerCommissionType,
      trackerCommissionPercentage: trackers[0].trackerCommissionPercentage,
      trackerCommissionFlat: trackers[0].trackerCommissionFlat,
      trackerIsActive: trackers[0].trackerIsActive,
      trackerIsPriority: trackers[0].trackerIsPriority,
      trackerIsFallback: trackers[0].trackerIsFallback,
      trackerIsExcluded: trackers[0].trackerIsExcluded,
      trackerIsSplitTraffic: trackers[0].trackerIsSplitTraffic,
      trackerIsPartialTraffic: trackers[0].trackerIsPartialTraffic,
      trackerTrafficChance: trackers[0].trackerTrafficChance,
      trackerIsCreatorOnly: Math.max(
        ...trackers.map((tracker) => tracker.trackerIsCreatorOnly)
      ),
      trackerCreatorIds: uniq(
        trackers.map((tracker) => tracker.trackerCreatorIds).flat()
      ).sort(),
    }))
  }, [campaign])

  const shownCreatorIds = uniq(
    campaign.trackers.map((tracker) => tracker.trackerCreatorIds).flat()
  ).sort()
  const showEndTrafficPercentage =
    !!campaign.campaign.campaignEndTrafficChanceDate

  // const domainsSingleColumn = campaign.campaign.campaignUrls?.length < 6
  const shownChannels = sortBy(campaign.channels, 'channelName')
  if (!expandedChannels) {
    shownChannels.splice(3, shownChannels.length)
  }

  return (
    <>
      <tr className="text-nowrap">
        <td
          className={varClass({
            'sort-highlight': sort === 'networkName',
          })}
        >
          <div className="text-bold m-b-1">
            {campaign.campaign.campaignName || (
              <span className="text-italic">Unknown campaign</span>
            )}
            {campaign.opportunity && (
              <WithTooltip
                className="campaign-alternative inline-block m-l-2"
                title="A new campaign can be found for this advertiser."
                text={
                  <SVG
                    src="/images/recommendations/icon-campaigns.svg"
                    width={18}
                    height={14}
                  />
                }
                id="advertiser-show-opportunity-tooltip"
                forceShow
              />
            )}
            {campaign.alternative && (
              <WithTooltip
                className="campaign-alternative inline-block m-l-2"
                title="A campaign with higher commission can be found for this advertiser."
                text={
                  <SVG
                    src="/images/recommendations/icon-alt-campaigns.svg"
                    width={20}
                    height={14}
                  />
                }
                id="advertiser-show-opportunity-tooltip"
                forceShow
              />
            )}
          </div>
          {campaign.campaign.campaignId && (
            <div className="text-smaller m-t-05">
              <span className="label-light">ID: </span>
              <span className="text-light">{campaign.campaign.campaignId}</span>
            </div>
          )}
          {campaign.network.networkName && (
            <div className="text-smaller m-t-05">
              <span className="label-light">Network: </span>
              <span className="text-light">{campaign.network.networkName}</span>
            </div>
          )}
          {campaign.trackers.length > 0 && (
            <div className="cursor-pointer m-t-2">
              <span
                className="text-light text-smaller text-bold background-new-gray-light p-x-2 p-y-05 border-round"
                onClick={() => setExpandedTrackers(!expandedTrackers)}
              >
                {expandedTrackers ? 'Hide' : 'See'} all subdomains (
                {campaign.trackers.length})
              </span>
            </div>
          )}
        </td>
        {campaign.isConnected ? (
          <>
            <td
              className={varClass({
                'p-y-3': true,
              })}
            >
              <div className="m-b-1">
                <span>Clicks: </span>
                <span className="value-badge value-badge-clickCount">
                  {formatStat(campaign.performance.clicksCount)}
                </span>
              </div>
              <div className="m-b-1">
                <span>Conversions: </span>
                <span className="value-badge value-badge-conversionCount">
                  {formatStat(campaign.performance.conversionsCount)}
                </span>
              </div>
              <div>
                <span>Revenue: </span>
                <span className="value-badge value-badge-conversionCommission">
                  {formatCurrencyReversed(
                    campaign.performance.revenue,
                    campaign.commissions.commissionCurrencyCode || 'DKK'
                  )}
                </span>
              </div>
            </td>
            <td
              className={varClass({
                'p-y-3': true,
                'sort-highlight': sort === 'campaignCommission',
              })}
            >
              <div className="vertical-middle">
                <Commission
                  type={campaign.commissions.commissionType}
                  value={formatCommission(
                    campaign.commissions.commissionType,
                    campaign.commissions.commissionActiveFlat,
                    campaign.commissions.commissionActivePercentage,
                    campaign.commissions.commissionCurrencyCode || 'DKK',
                    true
                  )}
                />
              </div>
            </td>
            <td className="p-3 text-wrap">
              {map(shownChannels, (channel) => (
                <span
                  key={channel.channelUuid}
                  className="badge badge-hoverable text-smaller text-nowrap m-05"
                  onClick={(event) => {
                    navigateTo(event, `/sites/${channel.channelUuid}`)
                  }}
                >
                  {channel.channelName}
                </span>
              ))}
              {!expandedChannels &&
                campaign.channels.length > shownChannels.length && (
                  <span
                    className="badge badge-hoverable text-smaller text-nowrap m-05"
                    onClick={(event) => {
                      setExpandedChannels(!expandedChannels)
                    }}
                  >
                    +{campaign.channels.length - shownChannels.length} channels
                  </span>
                )}
              {campaign.campaign.campaignIsRevshare && (
                <span className="badge badge-hoverable text-smaller text-nowrap m-05">
                  Heylink Boost
                </span>
              )}
            </td>
            {showCreatorIds && (
              <td className="text-left">
                {campaign.campaign.campaignIsCreatorOnly ? (
                  <div className="text-small text-light">Creators only</div>
                ) : null}
                {shownCreatorIds.map((creatorId: string) => (
                  <div key={creatorId} className="text-small">
                    {creatorId}
                  </div>
                ))}
              </td>
            )}
            {showTrafficPercentage && (
              <td className="text-center">
                <div className="row row-center row-fill row-nowrap">
                  <WithTooltip
                    title={
                      showEndTrafficPercentage
                        ? 'Current traffic percentage'
                        : ''
                    }
                    text={
                      campaign.campaign.campaignTrafficChance < 100
                        ? `${campaign.campaign.campaignTrafficChance}%`
                        : null
                    }
                    tooltipId="advertiser-show-opportunity-tooltip"
                    forceShow={showEndTrafficPercentage}
                  />
                  {showEndTrafficPercentage && (
                    <>
                      <SVG
                        width={14}
                        src="/images/overview2/icon-arrow.svg"
                        className="text-light m-x-15"
                      />
                      <WithTooltip
                        className="text-light"
                        title={`Traffic percentage after ${moment(
                          campaign.campaign.campaignEndTrafficChanceDate
                        ).format('YYYY-MM-DD')}`}
                        text={`${campaign.campaign.campaignEndTrafficChance}%`}
                        tooltipId="advertiser-show-opportunity-tooltip"
                        forceShow
                      />
                    </>
                  )}
                </div>
              </td>
            )}
            <td className="static p-r-4">
              {!campaign.campaign.campaignIsRevshare && (
                <>
                  {campaign.campaign.campaignIsActive === 1 ? (
                    <div className="row row-center row-space-between row-nowrap">
                      <div className="row row-center row-narrow row-nowrap">
                        {campaign.campaign.campaignIsPriority ? (
                          <SVG
                            src="/images/advertisers/icon-priority.svg"
                            title="Prioritized"
                          />
                        ) : null}
                        {campaign.campaign.campaignIsExcluded ? (
                          <SVG
                            src="/images/advertisers/icon-excluded.svg"
                            title="Disabled"
                          />
                        ) : null}
                      </div>
                      <TableActionMenu
                        campaign={campaign}
                        allCampaigns={allCampaigns}
                        advertiserUuid={advertiserUuid}
                        reloadCampaign={reloadCampaign}
                        showSnackbar={showSnackbar}
                        isLast={isLast}
                      />
                    </div>
                  ) : (
                    <div className="row row-center row-space-between row-nowrap">
                      <div className="row row-center row-narrow row-nowrap">
                        <SVG
                          src="/images/advertisers/icon-closed.svg"
                          title="Closed"
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </td>
          </>
        ) : (
          <>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            {showCreatorIds && <td>-</td>}
            {showTrafficPercentage && <td>-</td>}
            <td className="text-right p-r-4">
              <button
                className="btn btn-dark btn-xs"
                onClick={() => {
                  showModal(
                    <ConnectNetworkModal
                      network={networkDetails.find(
                        (network) =>
                          network.networkUuid === campaign.network.networkUuid
                      )}
                      channels={channels}
                      onSkip={navigateReload}
                      onDone={navigateReload}
                    />
                  )
                }}
              >
                Connect
              </button>
            </td>
          </>
        )}
      </tr>
      {expandedTrackers &&
        map(
          sortBy(shownExpandedTrackers, (tracker) =>
            [
              tracker.trackerIsActive ? 'A' : 'B',
              tracker.trackerUrlMatch,
              tracker.trackerChannelUuids.length,
            ].join(':')
          ),
          (tracker, index) => (
            <TableItemExpanded
              key={tracker.trackerUuids.join(',')}
              tracker={tracker}
              campaign={campaign}
              channelsMap={channelsMap}
              allCampaigns={allCampaigns}
              advertiserUuid={advertiserUuid}
              reloadCampaign={reloadCampaign}
              showSnackbar={showSnackbar}
              showCreatorIds={showCreatorIds}
              showTrafficPercentage={showTrafficPercentage}
              isLast={index === campaign.trackers.length - 1}
            />
          )
        )}
    </>
  )
}

const TableItemExpanded = (props) => {
  const {
    tracker,
    campaign,
    channelsMap,
    showCreatorIds,
    showTrafficPercentage,
    allCampaigns,
    advertiserUuid,
    reloadCampaign,
    showSnackbar,
    isLast,
  } = props

  const [showAllChannels, setShowAllChannels] = useState(false)
  const shownChannelUuids = showAllChannels
    ? tracker.trackerChannelUuids
    : tracker.trackerChannelUuids.slice(0, 3)

  return (
    <tr key={tracker.trackerUuids.join(',')}>
      <td className="static p-r-4" colSpan={2}>
        <div className="row row-space-between row-center">
          <div className="column column-fill text-smaller">
            <div>
              <span className="label-light">Domain: </span>
              {tracker.trackerUrlMatch}
            </div>
          </div>
        </div>
      </td>
      <td className="text-nowrap">
        <div className="vertical-middle">
          <Commission
            type={tracker.trackerCommissionType}
            value={formatCommission(
              tracker.trackerCommissionType,
              tracker.trackerCommissionFlat,
              tracker.trackerCommissionPercentage,
              campaign.commissions.commissionCurrencyCode || 'DKK',
              true
            )}
          />
        </div>
      </td>
      <td>
        {shownChannelUuids.map((trackerChannelUuid: string) => (
          <div key={trackerChannelUuid} className="text-nowrap">
            <span
              className="badge badge-hoverable text-smaller text-nowrap m-05"
              onClick={(event) => {
                if (channelsMap[trackerChannelUuid]) {
                  navigateTo(event, `/sites/${trackerChannelUuid}`)
                }
              }}
            >
              {channelsMap[trackerChannelUuid]
                ? channelsMap[trackerChannelUuid].channelName ||
                  'Unnamed channel'
                : 'Deleted channel'}
            </span>
            <a
              href={`${
                process.env.BACKEND_HOST
              }/tr/clicks/v1/${trackerChannelUuid}?targetUrl=${encodeURIComponent(
                'https://' + tracker.trackerUrlMatch
              )}`}
              target="_blank"
              rel="noreferrer"
              className="link link-lighter vertical-middle-self p-05 m-l-1"
              title="Example tracking link"
            >
              <SVG src="/images/icon-outside.svg" className="p-05" />
            </a>
          </div>
        ))}
        {tracker.trackerChannelUuids.length > 3 && (
          <div>
            <span
              className="badge badge-hoverable text-smaller text-nowrap m-05"
              onClick={() => setShowAllChannels(!showAllChannels)}
            >
              {showAllChannels ? 'Show less' : 'Show all sites'}
            </span>
          </div>
        )}
      </td>
      {showCreatorIds && (
        <td className="text-left">
          {tracker.trackerIsCreatorOnly ? (
            <div className="text-small text-light">Creators only</div>
          ) : null}
          {tracker.trackerCreatorIds.map((creatorId: string) => (
            <div key={creatorId} className="text-small">
              {creatorId}
            </div>
          ))}
        </td>
      )}
      {showTrafficPercentage && (
        <td className="text-center">
          {tracker.trackerTrafficChance < 100
            ? `${tracker.trackerTrafficChance}%`
            : null}
        </td>
      )}
      <td className="static p-r-4">
        {!campaign.campaign.campaignIsRevshare && (
          <div>
            {tracker.trackerIsActive === 1 ? (
              <div className="row row-center row-space-between row-nowrap">
                <div className="row row-center row-narrow row-nowrap">
                  {tracker.trackerIsPriority ? (
                    <SVG
                      src="/images/advertisers/icon-priority.svg"
                      title="Prioritized"
                    />
                  ) : null}
                  {tracker.trackerIsExcluded ? (
                    <SVG
                      src="/images/advertisers/icon-excluded.svg"
                      title="Disabled"
                    />
                  ) : null}
                </div>
                <TableActionMenu
                  campaign={{
                    ...campaign,
                    trackers: campaign.trackers.filter((t) =>
                      tracker.trackerUuids.includes(t.trackerUuid)
                    ),
                    channels: tracker.trackerChannelUuids
                      .map((channelUuid: string) => channelsMap[channelUuid])
                      .filter(Boolean),
                  }}
                  allCampaigns={allCampaigns}
                  advertiserUuid={advertiserUuid}
                  reloadCampaign={reloadCampaign}
                  showSnackbar={showSnackbar}
                  isTracker
                  isLast={isLast}
                />
              </div>
            ) : (
              <div className="row row-center row-space-between row-nowrap">
                <div className="row row-center row-narrow row-nowrap">
                  <SVG
                    src="/images/advertisers/icon-closed.svg"
                    title="Closed"
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </td>
    </tr>
  )
}

const TableActionMenu = (props) => {
  const {
    campaign,
    allCampaigns,
    advertiserUuid,
    reloadCampaign,
    showSnackbar,
    isLast,
    isTracker,
  } = props

  const { showModal, hideModal } = useModal()

  const ref = useRef<HTMLDivElement>()

  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (ref.current) {
      const offsetParent = ref.current.offsetParent as HTMLElement
      const top = offsetParent.offsetTop
      const scroll = ref.current.closest('.table-container-overflow').scrollTop
      const offset = isTracker ? 46 : 64
      const refEl = ref.current.querySelector('ul') as HTMLElement
      if (refEl) {
        refEl.style.top = `${top - scroll + offset}px`
      }
    }
  }, [open])

  useOutsideClick(ref, () => {
    setOpen(false)
  })

  const campaignUuids = useMemo(() => {
    return !isTracker
      ? campaign.campaign.campaignUuids || []
      : campaign.trackers.map((tracker) => tracker.trackerUuid)
  }, [campaign, isTracker])

  const campaignIsPriority = useMemo(() => {
    return !isTracker
      ? campaign.campaign.campaignIsPriority
      : campaign.trackers.some((tracker) => tracker.trackerIsPriority)
  }, [campaign, isTracker])

  const campaignIsExcluded = useMemo(() => {
    return !isTracker
      ? campaign.campaign.campaignIsExcluded
      : campaign.trackers.some((tracker) => tracker.trackerIsExcluded)
  }, [campaign, isTracker])

  const canSplitTest =
    uniq(allCampaigns.map((campaign) => campaign.connection.connectionUuid))
      .length > 1 && campaign.channels.length > 0
  const haveSplitTest = allCampaigns.some(
    (campaign) =>
      campaign.campaign.campaignIsSplitTraffic ||
      campaign.campaign.campaignIsPartialTraffic
  )

  const handlePrioritize = async () => {
    // if (campaign.channels.length === 1) {
    //   await putRequest(`/advs/v2/camps/tracking`, {
    //     campaignUuids,
    //     campaignIsPriority: true,
    //   })
    //   showSnackbar('Tracking priority updated')
    //   reloadCampaign()
    //   return
    // }

    showModal(
      <PrioritizeModal
        hideModal={hideModal}
        campaign={campaign}
        showSnackbar={showSnackbar}
        reloadCampaign={reloadCampaign}
      />,
      {
        cardClassName: 'prioritize-modal',
      }
    )
  }
  const handleUnprioritize = async () => {
    // if (campaign.channels.length === 1) {
    //   await putRequest(`/advs/v2/camps/tracking`, {
    //     campaignUuids,
    //     campaignIsPriority: false,
    //   })
    //   showSnackbar('Tracking priority updated')
    //   reloadCampaign()
    //   return
    // }

    showModal(
      <PrioritizeModal
        hideModal={hideModal}
        campaign={campaign}
        showSnackbar={showSnackbar}
        reloadCampaign={reloadCampaign}
      />,
      {
        cardClassName: 'prioritize-modal',
      }
    )
  }
  const handleDisable = async () => {
    // showSnackbar('Tracking disabled')
    // await putRequest(`/advs/v2/camps/tracking`, {
    //   campaignUuids,
    //   campaignIsExcluded: true,
    // })
    // reloadCampaign()

    showModal(
      <DisableModal
        hideModal={hideModal}
        campaign={campaign}
        showSnackbar={showSnackbar}
        reloadCampaign={reloadCampaign}
      />,
      {
        cardClassName: 'prioritize-modal',
      }
    )
  }
  const handleEnable = async () => {
    // showSnackbar('Tracking enabled')
    // await putRequest(`/advs/v2/camps/tracking`, {
    //   campaignUuids,
    //   campaignIsExcluded: false,
    // })
    // reloadCampaign()

    showModal(
      <DisableModal
        hideModal={hideModal}
        campaign={campaign}
        showSnackbar={showSnackbar}
        reloadCampaign={reloadCampaign}
      />,
      {
        cardClassName: 'prioritize-modal',
      }
    )
  }

  const handleSplit = () => {
    showModal(
      <SplitTestModal
        hideModal={hideModal}
        campaign={campaign}
        allCampaigns={allCampaigns}
        advertiserUuid={advertiserUuid}
        haveSplitTest={haveSplitTest}
        showSnackbar={showSnackbar}
        reloadCampaign={reloadCampaign}
      />,
      {
        cardClassName: 'abtest-modal',
      }
    )
  }

  const handleEditCreators = () => {
    showModal(
      <CreatorModal
        hideModal={hideModal}
        campaign={campaign}
        showSnackbar={showSnackbar}
        reloadCampaign={reloadCampaign}
      />,
      {
        cardClassName: 'creator-modal',
      }
    )
  }

  return (
    <div
      className={varClass({
        'advertiser-campaigns-actions': true,
        'open': open,
        'last': isLast,
      })}
      ref={ref}
    >
      <button className="link" onClick={() => setOpen(!open)}>
        <SVG src="/images/advertisers/icon-dots.svg" />
      </button>
      <ul>
        <li>
          <button
            className="link"
            onClick={(event) => {
              navigateTo(event, '/tools/urls')
            }}
          >
            Create Smart Link
          </button>
        </li>
        {canSplitTest && (
          <li>
            <button
              className="link"
              onClick={() => {
                setOpen(false)
                handleSplit()
              }}
            >
              {haveSplitTest ? 'Update' : 'Create'} split test
            </button>
          </li>
        )}
        {campaignUuids.length > 0 && (
          <li>
            <button
              className="link"
              onClick={() => {
                setOpen(false)
                handleEditCreators()
              }}
            >
              Edit creators
            </button>
          </li>
        )}
        {campaignUuids.length > 0 && (
          <>
            {campaignIsPriority ? (
              <li>
                <button
                  className="link"
                  onClick={async () => {
                    setOpen(false)
                    handleUnprioritize()
                  }}
                >
                  Unprioritize
                </button>
              </li>
            ) : (
              <li>
                <button
                  className="link"
                  onClick={async () => {
                    setOpen(false)
                    handlePrioritize()
                  }}
                >
                  Prioritize
                </button>
              </li>
            )}
            {campaignIsExcluded ? (
              <li>
                <button
                  className="link"
                  onClick={async () => {
                    setOpen(false)
                    handleEnable()
                  }}
                >
                  Enable
                </button>
              </li>
            ) : (
              <li>
                <button
                  className="link"
                  onClick={async () => {
                    setOpen(false)
                    handleDisable()
                  }}
                >
                  Disable
                </button>
              </li>
            )}
          </>
        )}
      </ul>
    </div>
  )
}

const ClosedCampaignTable = (props) => {
  const {
    title,
    campaigns,
    networkDetails,
    search,
    filters,
    channels,
    showModal,
    showSnackbar,
    className,
    reloadCampaign,
  } = props

  const { sort, dir, toggleSort } = useSortableTable({
    sort: 'networkName',
    dir: 'asc',
  })

  const headProps = { sort, dir, toggleSort }

  const [shownClosedCampaigns, hasMore] = useMemo(() => {
    const [filtered, hasMore] = campaignFilterWithLimit(
      campaigns,
      search,
      filters
    )
    const grouped = groupBy(
      campaignSort(filtered, sort, dir),
      (item: any) => item.network.networkUuid
    )
    return [
      Object.values(grouped).map((campaigns: any) => ({
        network: campaigns[0].network,
        campaigns: campaigns,
      })),
      hasMore,
    ]
  }, [search, filters, sort, dir])

  return (
    <div className={className || ''}>
      <div className="text-dark text-bold m-b-5">{title}</div>
      <div className="card-body-fill relative">
        <div className="table-container-overflow">
          <table className="table table-bodered table-sortable advertiser-campaigns-table">
            <thead className="table-sticky">
              <tr>
                <th className="text-nowrap">Campaign</th>
                <th className="text-nowrap">Performance 30 days</th>
                <th className="text-nowrap">Commission</th>
                <th className="text-nowrap">Sites</th>
              </tr>
            </thead>
            <tbody>
              {map(shownClosedCampaigns, (campaign) => (
                <ClosedCampaignTableItem
                  key={`${campaign.network.networkUuid}}`}
                  {...{
                    campaign,
                    networkDetails,
                    channels,
                    sort,
                    showModal,
                    showSnackbar,
                    reloadCampaign,
                  }}
                />
              ))}
              {isBlank(shownClosedCampaigns) && (
                <tr>
                  <td
                    colSpan={10}
                    className="text-center text-light text-bolder"
                  >
                    Nothing found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Tooltip
        id="advertiser-show-opportunity-tooltip"
        type="dark"
        effect="solid"
        place="bottom"
        rebuild={[shownClosedCampaigns]}
      />
    </div>
  )
}

const ClosedCampaignTableItem = (props) => {
  const { campaign } = props
  const [expanded, setExpanded] = useState(false)

  const expandCampaigns = useMemo(
    () => campaignSort(campaign.campaigns, 'networkName', 'asc'),
    [campaign]
  )

  return (
    <>
      <tr>
        <td colSpan={6}>
          <div className="row row-space-between row-center">
            <div className="text-bold">
              <span className="label-light">Network: </span>
              <span>{campaign.network.networkName}</span>
            </div>
            <div
              className="cursor-pointer text-smaller text-light"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? 'Hide campaigns' : 'Show campaigns'}
            </div>
          </div>
        </td>
      </tr>
      {expanded &&
        map(expandCampaigns, (c, index) => (
          <tr key={index}>
            <td>
              <div className="text-bold m-b-1">
                {c?.campaign?.campaignName || (
                  <span className="text-italic">Unknown campaign</span>
                )}
                {c.opportunity && (
                  <WithTooltip
                    className="campaign-alternative inline-block m-l-2"
                    title="A new campaign can be found for this advertiser."
                    text={
                      <SVG
                        src="/images/recommendations/icon-campaigns.svg"
                        width={18}
                        height={14}
                      />
                    }
                    id="advertiser-show-opportunity-tooltip"
                    forceShow
                  />
                )}
                {c.alternative && (
                  <WithTooltip
                    className="campaign-alternative inline-block m-l-2"
                    title="A campaign with higher commission can be found for this advertiser."
                    text={
                      <SVG
                        src="/images/recommendations/icon-alt-campaigns.svg"
                        width={20}
                        height={14}
                      />
                    }
                    id="advertiser-show-opportunity-tooltip"
                    forceShow
                  />
                )}
              </div>
              {c.campaign.campaignId && (
                <div className="text-smaller m-t-05">
                  <span className="label-light">ID: </span>
                  <span className="text-light">{c.campaign.campaignId}</span>
                </div>
              )}
            </td>
            {c.isConnected ? (
              <>
                <td
                  className={varClass({
                    'p-y-3': true,
                  })}
                >
                  <div className="m-b-1">
                    <span>Clicks: </span>
                    <span className="value-badge value-badge-clickCount">
                      {formatStat(c.performance.clicksCount)}
                    </span>
                  </div>
                  <div className="m-b-1">
                    <span>Conversions: </span>
                    <span className="value-badge value-badge-conversionCount">
                      {formatStat(c.performance.conversionsCount)}
                    </span>
                  </div>
                  <div>
                    <span>Revenue: </span>
                    <span className="value-badge value-badge-conversionCommission">
                      {formatCurrencyReversed(
                        c.performance.revenue,
                        c.commissions.commissionCurrencyCode || 'DKK'
                      )}
                    </span>
                  </div>
                </td>
                <td
                  className={varClass({
                    'p-y-3': true,
                  })}
                >
                  <div className="vertical-middle">
                    <Commission
                      type={c.commissions.commissionType}
                      value={formatCommission(
                        c.commissions.commissionType,
                        c.commissions.commissionFlat,
                        c.commissions.commissionPercentage,
                        c.commissions.commissionCurrencyCode || 'DKK',
                        true
                      )}
                    />
                  </div>
                </td>
                <td className="p-3 text-wrap">
                  {map(sortBy(c.channels, 'channelName'), (channel) => (
                    <span
                      key={channel.channelUuid}
                      className="badge badge-hoverable text-smaller text-nowrap m-05"
                      onClick={(event) => {
                        navigateTo(event, `/sites/${channel.channelUuid}`)
                      }}
                    >
                      {channel.channelName}
                    </span>
                  ))}
                </td>
              </>
            ) : (
              <>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </>
            )}
          </tr>
        ))}
    </>
  )
}

const TrafficTable = (props) => {
  const { title, data, channels, className } = props

  const { sort, dir, toggleSort } = useSortableTable({
    sort: 'clickCount',
    dir: 'desc',
  })

  const headProps = { sort, dir, toggleSort }

  const shownRows = trafficSort(data.rows, sort, dir)

  return (
    <div className={className || ''}>
      <div className="row row-center row-space-between m-b-5">
        <div className="text-dark text-bold">{title}</div>
        <div className="text-light text-small">
          {data?.rows?.length || 0} {plural(data?.rows?.length || 0, 'page')}
        </div>
      </div>
      <div className="card-body-fill">
        <div className="table-container-overflow">
          <table className="table table-bodered table-sortable advertiser-campaigns-table">
            <thead className="table-sticky">
              <tr>
                <TableHead value="grp" label="Page" {...headProps} />
                <TableHead value="clickCount" label="Clicks" {...headProps} />
                <TableHead
                  value="conversionCount"
                  label="Conversions"
                  {...headProps}
                />
                <TableHead
                  value="conversionCommission"
                  label="Revenue"
                  {...headProps}
                />
                {/*<th className="text-nowrap">Actions</th>*/}
              </tr>
            </thead>
            <tbody>
              {map(shownRows, (row, index) => (
                <TrafficTableItem
                  key={row.grp}
                  {...{
                    row,
                    sort,
                    dir,
                    channels,
                  }}
                  isLast={index === shownRows.length - 1}
                />
              ))}
              {isBlank(shownRows) && (
                <tr>
                  <td
                    colSpan={10}
                    className="text-center text-light text-bolder"
                  >
                    Nothing found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const TrafficTableItem = (props) => {
  const { row, sort } = props

  const { grp, name } = row
  const values = calculateValues(row)

  return (
    <tr className="text-nowrap">
      <td
        className={varClass({
          'sort-highlight': sort === 'grp',
        })}
      >
        <div className="text-bold m-b-1">{name || grp || 'Unknown'}</div>
        {/*
        <div>
          <span className="label-light">Channel: </span>
          <span className="text-light">{'?!?!?'}</span>
        </div>
        */}
      </td>
      <td
        className={varClass({
          'sort-highlight': sort === 'clickCount',
        })}
      >
        <div className="value-badge value-badge-clickCount">
          {values.clickCount}
        </div>
      </td>
      <td
        className={varClass({
          'sort-highlight': sort === 'conversionCount',
        })}
      >
        <div className="value-badge value-badge-conversionCount">
          {values.conversionCount}
        </div>
      </td>
      <td
        className={varClass({
          'sort-highlight': sort === 'conversionCommission',
        })}
      >
        <div className="value-badge value-badge-conversionCommission">
          {values.conversionTotalCommission || values.conversionCommission}
        </div>
      </td>
    </tr>
  )
}

const CampaignHistory = (props) => {
  const { data, campaigns, channels, networks } = props

  const [filters, setFilters] = useState([
    {
      key: 'channelUuids',
      value: '',
      label: '',
    },
    {
      key: 'networkUuids',
      value: '',
      label: '',
    },
  ])

  const [dateLimit, setDateLimit] = useState('1m')
  const selectedDateLimit = useMemo(() => {
    if (dateLimit) {
      const value: number = parseInt(
        dateLimit.substring(0, dateLimit.length - 1)
      )
      let type: 'day' | 'week' | 'month' = 'day'
      switch (dateLimit[dateLimit.length - 1]) {
        case 'w':
          type = 'week'
          break
        case 'm':
          type = 'month'
          break
      }

      return moment().subtract(value, type).format('YYYY-MM-DD')
    } else {
      return null
    }
  }, [dateLimit])

  const filterReset =
    filters
      .filter((filter) => filter.value)
      .map((filter) => filter.value)
      .join(',') + dateLimit

  const shownEvents = useMemo(() => {
    if (data?.events) {
      return data.events.filter((event) => {
        if (selectedDateLimit) {
          if (event.event.eventDatetime.substring(0, 10) < selectedDateLimit)
            return false
        }
        for (const filter of filters) {
          if (filter.value) {
            switch (filter.key) {
              case 'channelUuid':
                if (event.channel[filter.key] !== filter.value) return false
                break
              case 'networkUuid':
                if (event.network[filter.key] !== filter.value) return false
                break
              default:
            }
          }
        }
        return true
      })
    } else {
      return []
    }
  }, [filterReset])

  const groupedByCampaign = useMemo(() => {
    const grouped = groupBy(campaigns, (e) => e.campaign.campaignId)
    return Object.keys(grouped)
      .map((key) => {
        const campaignUuids = grouped[key]
          .map((item) => item.campaign.campaignUuids)
          .flat()
        const events = shownEvents.filter((item) =>
          campaignUuids.some((uuid) => uuid === item.campaign.campaignUuid)
        )
        if (!events.length) return undefined
        return {
          campaign: grouped[key][0].campaign,
          network: grouped[key][0].network,
          events,
        }
      })
      .filter((v) => v)
  }, [campaigns, shownEvents])

  return (
    <div>
      <div className="row row-center row-space-between">
        <div className="vertical-middle m-b-7">
          <CampaignsFilter {...{ filters, setFilters, channels, networks }} />
        </div>
        <div>
          <CampaignsDateLimitPicker {...{ dateLimit, setDateLimit }} />
        </div>
      </div>

      <div className="text-dark text-bold m-t-30 m-b-5">Change log</div>
      <div className="card-body-fill">
        <div className="table-container-overflow">
          <table className="table table-bodered table-sortable advertiser-campaigns-table">
            <thead className="table-sticky">
              <tr>
                <th>Campaign</th>
                <th>Action</th>
                <th>Change</th>
                <th>Site</th>
                <th className="text-right">Time</th>
              </tr>
            </thead>
            <tbody>
              {!isBlank(shownEvents) ? (
                <>
                  {map(groupedByCampaign, (item) => (
                    <GroupEvent
                      key={item.campaign.campaignId}
                      groupEvent={item}
                    />
                  ))}
                </>
              ) : (
                <tr>
                  <td
                    colSpan={10}
                    className="text-center text-light text-bolder"
                  >
                    Nothing found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

const GroupEvent = (props) => {
  const { groupEvent } = props
  const [expanded, setExpanded] = useState(false)

  return (
    <>
      <tr>
        <td colSpan={5} className="p-l-40">
          <div className="row row-space-between row-center">
            <div>
              <HistoryCampaign
                network={groupEvent.network}
                campaign={groupEvent.campaign}
              />
            </div>
            <div
              className="cursor-pointer text-smaller text-light"
              onClick={() => setExpanded(!expanded)}
            >
              {expanded ? 'Hide events' : 'Show events'}
            </div>
          </div>
        </td>
      </tr>
      {expanded &&
        map(groupEvent.events, (event, index) => (
          <tr key={index}>
            <td className="p-l-40">
              <HistoryCampaign
                network={groupEvent.network}
                campaign={groupEvent.campaign}
              />
            </td>
            <td className="text-wrap text-capitalize">{event.event.event}</td>
            <td>
              <HistoryEvent event={event.event} />
            </td>
            <td className="text-wrap">
              <span
                className="badge badge-hoverable text-smaller text-nowrap"
                onClick={(e) => {
                  navigateTo(e, `/sites/${event.channel.channelUuid}`)
                }}
              >
                {event.channel.channelName}
              </span>
            </td>
            <td className="text-right">{event.event.eventDatetime}</td>
          </tr>
        ))}
    </>
  )
}

export const HistoryCampaign = (props) => {
  const { campaign, network } = props

  return (
    <>
      <div className="text-bold p-l-1 m-b-05">{campaign.campaignName}</div>
      <div className="row" style={{ gap: '2px 16px' }}>
        {campaign?.campaignId && (
          <div className="p-l-1 text-smaller">
            <span className="label-light">ID: </span>
            <span className="text-light">{campaign.campaignId}</span>
          </div>
        )}
        {network.networkName && (
          <div className="p-l-1 text-smaller">
            <span className="label-light">Network: </span>
            <span className="text-light">{network.networkName}</span>
          </div>
        )}
      </div>
    </>
  )
}

export const HistoryEvent = (props) => {
  const { event } = props

  if (event.fromIsActive !== event.toIsActive) {
    if (event.toIsActive) {
      return (
        <span className="badge badge-rounder text-smaller text-bold text-nowrap text-new-green">
          Campaign created
        </span>
      )
    } else {
      return (
        <span className="badge badge-rounder text-smaller text-bold text-nowrap text-light">
          Campaign closed
        </span>
      )
    }
  }

  const fromValue = event.fromCommissionFlat || event.fromCommissionPercentage
  const toValue = event.toCommissionFlat || event.toCommissionPercentage
  const isPositive = toValue > fromValue

  return (
    <span
      className={varClass({
        'campaign-change text-smaller text-bold text-nowrap vertical-middle':
          true,
      })}
    >
      <span
        className="text-primary text-bold"
        style={{ position: 'relative', top: -0.5 }}
      >
        {formatCommission(
          '',
          event.fromCommissionFlat,
          event.fromCommissionPercentage,
          event.currencyCode || 'DKK',
          true
        )}
      </span>
      <SVG
        src="/images/advertisers/chevron-right.svg"
        className="m-x-05"
        style={{ position: 'relative', top: -0.5 }}
      />
      <span
        className={varClass({
          'campaign-change-new-value vertical-middle': true,
          'text-new-green': isPositive,
          'text-new-blood': !isPositive,
        })}
      >
        {formatCommission(
          '',
          event.toCommissionFlat,
          event.toCommissionPercentage,
          event.currencyCode || 'DKK',
          true
        )}
        {isPositive ? (
          <SVG
            src="/images/caret-up.svg"
            width={8}
            height={4}
            className="m-l-1 m-t--05"
          />
        ) : (
          <SVG
            src="/images/caret-down.svg"
            width={8}
            height={4}
            className="m-l-1"
          />
        )}
      </span>
    </span>
  )
}

export const PrioritizeModal = (props) => {
  const { campaign, hideModal, reloadCampaign, showSnackbar } = props
  const trackers = campaign.trackers
  const [selectedMethod, setSelectedMethod] = useState('')

  const initialSelectedChannels = useMemo(
    () =>
      uniq(
        trackers
          .filter((tracker) => tracker.trackerIsPriority)
          .map((tracker) => tracker.trackerChannelUuid)
      ),
    [campaign]
  )
  const {
    values: selectedChannels,
    toggle: toggleSelectedChannel,
    clear: clearSelectedChannels,
  } = useArray(initialSelectedChannels)

  const canUnprioritizeAll = useMemo(
    () => !!initialSelectedChannels.length,
    [campaign]
  )

  const submit = async () => {
    let priorityCampaignUuids = []
    let unpriorityCampaignUuids = []

    if (selectedMethod === 'specific') {
      if (selectedChannels.length) {
        priorityCampaignUuids = trackers
          .filter((tracker) =>
            selectedChannels.includes(tracker.trackerChannelUuid)
          )
          .map((t) => t.trackerUuid)
      } else {
        priorityCampaignUuids = []
      }
    } else if (selectedMethod === 'all') {
      priorityCampaignUuids = trackers.map((tracker) => tracker.trackerUuid)
    } else if (selectedMethod === 'none') {
      priorityCampaignUuids = []
    }

    for (const tracker of trackers) {
      if (!priorityCampaignUuids.includes(tracker.trackerUuid)) {
        unpriorityCampaignUuids.push(tracker.trackerUuid)
      }
    }

    priorityCampaignUuids = priorityCampaignUuids.filter((uuid) => uuid)
    unpriorityCampaignUuids = unpriorityCampaignUuids.filter((uuid) => uuid)

    await Promise.all([
      priorityCampaignUuids.length
        ? putRequest(`/advs/v2/camps/tracking`, {
            campaignUuids: priorityCampaignUuids,
            campaignIsPriority: true,
          })
        : null,
      unpriorityCampaignUuids.length
        ? putRequest(`/advs/v2/camps/tracking`, {
            campaignUuids: unpriorityCampaignUuids,
            campaignIsPriority: false,
          })
        : null,
    ])

    showSnackbar('Tracking priority updated')
    reloadCampaign()
    hideModal()
  }

  return (
    <>
      <div className="card-body">
        <div
          onChange={(e: any) => setSelectedMethod(e.target.value)}
          className="column column-narrow"
        >
          <div className="control control-radio">
            <input type="radio" name="campaign-priority" value="all" />
            <label>Prioritize for all sites</label>
          </div>
          {canUnprioritizeAll && (
            <div className="control control-radio">
              <input type="radio" name="campaign-priority" value="none" />
              <label>Unprioritize for all sites</label>
            </div>
          )}
          <div className="control control-radio">
            <input type="radio" name="campaign-priority" value="specific" />
            <label>Prioritize for specific sites</label>
          </div>
        </div>

        <div
          className={varClass({
            'subnav-filters w-100 m-t-2': true,
            'disabled': selectedMethod !== 'specific',
          })}
        >
          <MultiSelectDropdown
            label="Sites"
            values={campaign.channels}
            sortKey="channelUuid"
            uuidGetter={(channel: IChannelsV1List) => channel.channelUuid}
            labelGetter={(channel: IChannelsV1List) =>
              channel.channelName || channel.channelDomain
            }
            selectedValues={selectedChannels}
            toggleSelectedValue={toggleSelectedChannel}
            clearSelectedValues={clearSelectedChannels}
          />
        </div>

        <div className="row row-end m-t-5">
          <button
            className="btn btn-dark"
            disabled={!selectedMethod}
            onClick={submit}
          >
            Update
          </button>
        </div>
      </div>
    </>
  )
}

export const DisableModal = (props) => {
  const { campaign, hideModal, reloadCampaign, showSnackbar } = props
  const trackers = campaign.trackers
  const [selectedMethod, setSelectedMethod] = useState('')

  const initialSelectedChannels = useMemo(
    () =>
      uniq(
        trackers
          .filter((tracker) => tracker.trackerIsExcluded)
          .map((tracker) => tracker.trackerChannelUuid)
      ),
    [campaign]
  )
  const {
    values: selectedChannels,
    toggle: toggleSelectedChannel,
    clear: clearSelectedChannels,
  } = useArray(initialSelectedChannels)

  const canIncludeAll = useMemo(
    () => !!initialSelectedChannels.length,
    [campaign]
  )

  const submit = async () => {
    let excludeCampaignUuids = []
    let includeCampaignUuids = []

    if (selectedMethod === 'specific') {
      if (selectedChannels.length) {
        excludeCampaignUuids = trackers
          .filter((tracker) =>
            selectedChannels.includes(tracker.trackerChannelUuid)
          )
          .map((t) => t.trackerUuid)
      } else {
        excludeCampaignUuids = []
      }
    } else if (selectedMethod === 'all') {
      excludeCampaignUuids = trackers.map((tracker) => tracker.trackerUuid)
    } else if (selectedMethod === 'none') {
      excludeCampaignUuids = []
    }

    for (const tracker of trackers) {
      if (!excludeCampaignUuids.includes(tracker.trackerUuid)) {
        includeCampaignUuids.push(tracker.trackerUuid)
      }
    }

    excludeCampaignUuids = excludeCampaignUuids.filter((uuid) => uuid)
    includeCampaignUuids = includeCampaignUuids.filter((uuid) => uuid)

    await Promise.all([
      excludeCampaignUuids.length
        ? putRequest(`/advs/v2/camps/tracking`, {
            campaignUuids: excludeCampaignUuids,
            campaignIsExcluded: true,
          })
        : null,
      includeCampaignUuids.length
        ? putRequest(`/advs/v2/camps/tracking`, {
            campaignUuids: includeCampaignUuids,
            campaignIsExcluded: false,
          })
        : null,
    ])

    showSnackbar('Tracking priority updated')
    reloadCampaign()
    hideModal()
  }

  return (
    <>
      <div className="card-body">
        <div
          onChange={(e: any) => setSelectedMethod(e.target.value)}
          className="column column-narrow"
        >
          <div className="control control-radio">
            <input type="radio" name="campaign-priority" value="all" />
            <label>Disable for all sites</label>
          </div>
          {canIncludeAll && (
            <div className="control control-radio">
              <input type="radio" name="campaign-priority" value="none" />
              <label>Enable for all sites</label>
            </div>
          )}
          <div className="control control-radio">
            <input type="radio" name="campaign-priority" value="specific" />
            <label>Disable for specific sites</label>
          </div>
        </div>

        <div
          className={varClass({
            'subnav-filters w-100 m-t-2': true,
            'disabled': selectedMethod !== 'specific',
          })}
        >
          <MultiSelectDropdown
            label="Sites"
            values={campaign.channels}
            sortKey="channelUuid"
            uuidGetter={(channel: IChannelsV1List) => channel.channelUuid}
            labelGetter={(channel: IChannelsV1List) =>
              channel.channelName || channel.channelDomain
            }
            selectedValues={selectedChannels}
            toggleSelectedValue={toggleSelectedChannel}
            clearSelectedValues={clearSelectedChannels}
          />
        </div>

        <div className="row row-end m-t-5">
          <button
            className="btn btn-dark"
            disabled={!selectedMethod}
            onClick={submit}
          >
            Update
          </button>
        </div>
      </div>
    </>
  )
}

const SplitTestModal = (props) => {
  const {
    campaign,
    allCampaigns,
    advertiserUuid,
    haveSplitTest,
    hideModal,
    reloadCampaign,
    showSnackbar,
  } = props

  const connections = useApiGet<INetworksV1Connections[]>(
    '/networks/v1/connections'
  )
  const availableChannels = uniqBy(
    allCampaigns.map((campaign) => campaign.channels || []).flat(),
    (channel: { channelUuid: string }) => channel.channelUuid
  )

  const availableConnections = useMemo(() => {
    if (!connections) return []
    const connectionUuids = uniq(
      allCampaigns.map((campaign) => campaign.connection.connectionUuid)
    )
    return connections
      .filter((connection) =>
        connectionUuids.includes(connection.connectionUuid)
      )
      .sort()
  }, [connections, allCampaigns])

  const [connectionA, setConnectionA] = useState(
    campaign.connection.connectionUuid
  )
  const [connectionB, setConnectionB] = useState('')

  const [campaignMethod, setCampaignMethod] = useState('all')
  const {
    values: domains,
    addAll: setDomains,
    toggle: toggleDomain,
    clear: clearDomains,
  } = useArray()
  const availableDomains = useMemo(() => {
    clearDomains()
    const domainsA = uniq(
      allCampaigns
        .filter(
          (campaign) => campaign.connection.connectionUuid === connectionA
        )
        .map((campaign) => campaign.campaign.campaignUrls)
        .flat()
    )
    const domainsB = uniq(
      allCampaigns
        .filter(
          (campaign) => campaign.connection.connectionUuid === connectionB
        )
        .map((campaign) => campaign.campaign.campaignUrls)
        .flat()
    )
    return domainsA.filter((domain) => domainsB.includes(domain))
  }, [connectionA, connectionB])

  const [splitPercentage, setSplitPercentage] = useState(50)
  const [scheduleEnabled, setScheduleEnabled] = useState(false)
  const [endDate, setEndDate] = useState(moment().add(7, 'days').toDate())
  const [endSplitPercentage, setEndSplitPercentage] = useState(50)

  const [channelMethod, setChannelMethod] = useState('')
  const {
    values: channels,
    set: setChannels,
    toggle: toggleChannel,
    clear: clearChannels,
  } = useArray(campaign.channels.map((channel) => channel.channelUuid))

  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')

  const [isUpdate, setIsUpdate] = useState(false)

  useEffect(() => {
    // Restore current split test values
    if (haveSplitTest) {
      const currentSplitTrackers = allCampaigns
        .map((campaign) => campaign.trackers)
        .flat()
        .filter((tracker) => tracker.trackerIsSplitTraffic)
      if (!currentSplitTrackers.length) return

      const currentSplitCampaigns = allCampaigns.filter(
        (campaign) => campaign.campaign.campaignIsSplitTraffic
      )
      if (!currentSplitCampaigns.length) return

      // ConnectionB
      const initialSplit: Record<string, number> = {}
      for (const campaign of currentSplitCampaigns) {
        initialSplit[campaign.connection.connectionUuid] =
          campaign.campaign.campaignTrafficChance
      }
      const initialConnectionUuids = Object.keys(initialSplit)
      const initialConnectionB = initialConnectionUuids.filter(
        (connectionUuid) => connectionUuid !== connectionA
      )[0]

      // Domains
      const initialDomains = uniq(
        currentSplitTrackers.map((tracker) => tracker.trackerUrlMatch)
      )
      const allDomains = uniq(
        currentSplitCampaigns.map((campaign) => campaign.campaign.campaignUrl)
      )
      const isAllDomains = initialDomains.length >= allDomains.length

      // Channels
      const initialChannels = uniq(
        currentSplitTrackers.map((tracker) => tracker.trackerChannelUuid)
      )
      const allChannels = uniq(
        allCampaigns
          .map((campaign) =>
            campaign.channels.map((channel) => channel.channelUuid)
          )
          .flat()
      )
      const isAllChannels = initialChannels.length >= allChannels.length

      if (
        initialConnectionUuids.length !== 2 ||
        !initialConnectionUuids.includes(connectionA) ||
        !initialConnectionB ||
        !initialChannels?.length
      ) {
        return
      }

      setConnectionB(initialConnectionB)
      setSplitPercentage(initialSplit[connectionA])

      if (isAllDomains) {
        setCampaignMethod('all')
      } else {
        setCampaignMethod('specific')
      }
      setTimeout(() => setDomains(initialDomains), 300)

      if (isAllChannels) {
        setChannelMethod('all')
      } else {
        setChannelMethod('specific')
      }
      setTimeout(() => setChannels(initialChannels), 300)

      setIsUpdate(true)
    }
  }, [haveSplitTest])

  const onSubmit = async () => {
    setError('')

    const channelUuids = []
    if (channelMethod === 'specific') {
      if (channels.length) {
        channelUuids.push(...channels)
      } else {
        channelUuids.push(
          ...availableChannels.map((channel) => channel.channelUuid)
        )
      }
    } else if (channelMethod === 'all') {
      channelUuids.push(
        ...availableChannels.map((channel) => channel.channelUuid)
      )
    }

    setSubmitting(true)

    if (haveSplitTest) {
      await deleteRequest(`/advs/v1/camps/split`, {
        advertiserUuid,
      })
    }

    const params: any = {
      advertiserUuid,
      channelUuids,
      connectionUuidA: connectionA,
      connectionUuidB: connectionB,
      trafficSplit: splitPercentage,
    }
    if (campaignMethod === 'specific') {
      params.domains = domains
    }
    if (scheduleEnabled) {
      params.endTrafficSplit = endSplitPercentage
      params.endDate = moment(endDate).format('YYYY-MM-DD')
    }
    const response = await postRequest(`/advs/v1/camps/split`, params)

    setSubmitting(false)

    if (response.code === 200) {
      showSnackbar('Traffic split created')
      reloadCampaign()
      hideModal()
    } else {
      setError(response.message)
    }
  }

  const onDisable = async () => {
    setSubmitting(true)

    await deleteRequest(`/advs/v1/camps/split`, {
      advertiserUuid,
    })

    setSubmitting(false)

    showSnackbar('Traffic split removed')
    reloadCampaign()
    hideModal()
  }

  if (!connections || !availableChannels) {
    return (
      <div className="card-body">
        <div className="row row-center m-b-4">
          <SmallLoadable />
        </div>
      </div>
    )
  }

  if (availableConnections?.length <= 1) {
    hideModal()
  }

  const isNew = !haveSplitTest

  return (
    <>
      <div className="card-header">
        <div className="card-title row row-space-between">
          {isNew ? 'Create' : 'Start'} a new split test
        </div>
      </div>

      <div className="card-body">
        <div className="row row-space-between row-nowrap m-b-4">
          <div className="flex-1">
            <div className="control control-select w-100">
              <label>Network</label>
              <select
                value={connectionA}
                onChange={(e) => {
                  setConnectionA(e.target.value)
                  if (e.target.value === connectionB) {
                    setConnectionB(
                      availableConnections.filter(
                        (connection) =>
                          connection.connectionUuid !== e.target.value
                      )[0]?.connectionUuid || ''
                    )
                  }
                }}
              >
                <option value="">Select network</option>
                {availableConnections.map((connection) => (
                  <option
                    key={connection.connectionUuid}
                    value={connection.connectionUuid}
                  >
                    {connection.network.networkName} -{' '}
                    {connection.connectionName ||
                      connection.connectionPartnerId ||
                      ''}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex-1">
            <div className="control control-select w-100">
              <label>Network</label>
              <select
                value={connectionB}
                onChange={(e) => {
                  setConnectionB(e.target.value)
                  if (e.target.value === connectionA) {
                    setConnectionA(
                      availableConnections.filter(
                        (connection) =>
                          connection.connectionUuid !== e.target.value
                      )[0]?.connectionUuid || ''
                    )
                  }
                }}
              >
                <option value="">Select network</option>
                {availableConnections.map((connection) => (
                  <option
                    key={connection.connectionUuid}
                    value={connection.connectionUuid}
                  >
                    {connection.network.networkName} -{' '}
                    {connection.connectionName ||
                      connection.connectionPartnerId ||
                      ''}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="control control-checkbox m-b-2">
          <input
            type="checkbox"
            checked={campaignMethod === 'specific'}
            onChange={(event) =>
              setCampaignMethod(event.target.checked ? 'specific' : 'all')
            }
          />
          <label>Choose specific domains</label>
        </div>
        <Collapse open={campaignMethod === 'specific'}>
          <div
            className={varClass({
              'subnav-filters w-50 m-b-6': true,
              'disabled': campaignMethod !== 'specific',
            })}
          >
            <MultiSelectDropdown
              label="Domains"
              values={availableDomains}
              sortKey="domain"
              uuidGetter={(domain: string) => domain}
              labelGetter={(domain: string) => domain}
              selectedValues={domains}
              toggleSelectedValue={toggleDomain}
              clearSelectedValues={clearDomains}
            />
          </div>
        </Collapse>

        <div className="control control-range w-100 m-b-4">
          <div className="row row-space-between">
            <label className="text-light text-bold">{splitPercentage}%</label>
            <label className="text-light text-bold">
              {100 - splitPercentage}%
            </label>
          </div>
          <input
            type="range"
            min="1"
            max="99"
            value={splitPercentage}
            onChange={(e) => {
              setSplitPercentage(parseInt(e.target.value))
            }}
          />
        </div>

        <div className="control control-checkbox m-b-2">
          <input
            type="checkbox"
            checked={scheduleEnabled}
            onChange={(event) => setScheduleEnabled(event.target.checked)}
          />
          <label>Schedule percentage change</label>
        </div>

        <Collapse open={scheduleEnabled}>
          <div className="m-b-4">
            <div className="row row-center m-b-4">
              <label className="text-small text-bold text-light">
                Gradually change percentage until
              </label>
              <div className="control control-date">
                <DateInput
                  selected={endDate}
                  onChange={(newDate) => setEndDate(newDate)}
                  highlightDates={[new Date()]}
                  minDate={new Date()}
                />
              </div>
            </div>
            <div className="control control-range w-100">
              <div className="row row-space-between">
                <label className="text-light text-bold">
                  {endSplitPercentage}%
                </label>
                <label className="text-light text-bold">
                  {100 - endSplitPercentage}%
                </label>
              </div>
              <input
                type="range"
                min="0"
                max="100"
                value={endSplitPercentage}
                onChange={(e) => {
                  setEndSplitPercentage(parseInt(e.target.value))
                }}
              />
            </div>
          </div>
        </Collapse>

        <div className="column column-narrow m-b-2">
          <div className="control control-radio">
            <input
              type="radio"
              name="campaign-priority"
              value="all"
              checked={channelMethod === 'all'}
              onChange={(e: any) => setChannelMethod(e.target.value)}
            />
            <label>Compare on all sites</label>
          </div>
          <div className="control control-radio">
            <input
              type="radio"
              name="campaign-priority"
              value="specific"
              checked={channelMethod === 'specific'}
              onChange={(e: any) => setChannelMethod(e.target.value)}
            />
            <label>Compare on specific sites</label>
          </div>
        </div>

        <div
          className={varClass({
            'subnav-filters w-50 m-b-4': true,
            'disabled': channelMethod !== 'specific',
          })}
        >
          <MultiSelectDropdown
            label="Sites"
            values={availableChannels}
            sortKey="channelUuid"
            uuidGetter={(channel: IChannelsV1List) => channel.channelUuid}
            labelGetter={(channel: IChannelsV1List) =>
              channel.channelName || channel.channelDomain
            }
            selectedValues={channels}
            toggleSelectedValue={toggleChannel}
            clearSelectedValues={clearChannels}
          />
        </div>

        <div className="row row-space-between row-center row-nowrap m-t-4">
          <div className="text-red max-w-50">{error}</div>
          <div className="row">
            {haveSplitTest && (
              <button
                className="btn btn-bordered"
                disabled={submitting}
                onClick={onDisable}
              >
                Disable split test
              </button>
            )}

            <button
              className="btn btn-dark"
              disabled={
                !connectionA || !connectionB || !channelMethod || submitting
              }
              onClick={onSubmit}
              data-tip={
                haveSplitTest && !isUpdate
                  ? 'Previous split test will be disabled'
                  : null
              }
              data-for="split-test-tooltip"
            >
              {isUpdate ? 'Update' : 'Create'}
            </button>

            <Tooltip
              id="split-test-tooltip"
              title="Previous split test will be disabled"
              type="light"
              place="bottom"
            />
          </div>
        </div>
      </div>
    </>
  )
}

const CreatorModal = (props) => {
  const { campaign, hideModal, reloadCampaign, showSnackbar } = props

  const [initialCreatorsOnly, initialCreatorIds] = useMemo(() => {
    return [
      campaign.trackers.every((tracker) => tracker.trackerIsCreatorOnly),
      uniq(
        campaign.trackers
          .map((tracker) => tracker.trackerCreatorIds.split(','))
          .flat()
      )
        .sort()
        .filter((id) => !!id),
    ]
  }, [campaign])

  const [creatorsOnly, setCreatorsOnly] = useState(initialCreatorsOnly)
  const [creatorIdsMethod, setCreatorIdsMethod] = useState(
    initialCreatorIds.length ? 'specific' : 'all'
  )
  const {
    values: creatorIds,
    addAll: addAllCreatorIds,
    remove: removeCreatorId,
  } = useArray(initialCreatorIds)
  const [newCreatorId, setNewCreatorId] = useState('')

  const trackerUuids = useMemo(
    () => campaign.trackers.map((tracker) => tracker.trackerUuid),
    campaign
  )
  const addCreatorIds = () => {
    const newCreatorIds = newCreatorId
      .split(',')
      .map((id) => id.trim())
      .filter((id) => !!id)
    addAllCreatorIds(newCreatorIds)
    setNewCreatorId('')
  }

  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState('')
  const onSubmit = async () => {
    setSubmitting(true)
    setError('')

    const params = {
      trackerUuids,
      trackerIsCreatorOnly: creatorsOnly,
      trackerCreatorIds: [],
    }
    if (creatorIdsMethod === 'specific') {
      params.trackerCreatorIds = creatorIds
    }
    const response = await postRequest(`/advs/v1/camps/creators`, params)

    setSubmitting(false)

    if (response.code === 200) {
      showSnackbar('Creator options updated')
      reloadCampaign()
      hideModal()
    } else {
      setError(response.message)
    }
  }
  const onDisable = async () => {
    setSubmitting(true)
    setError('')

    const response = await postRequest(`/advs/v1/camps/creators`, {
      trackerUuids,
      trackerIsCreatorOnly: false,
      trackerCreatorIds: [],
    })

    setSubmitting(false)

    if (response.code === 200) {
      showSnackbar('Creator options updated')
      reloadCampaign()
      hideModal()
    } else {
      setError(response.message)
    }
    setSubmitting(false)
  }

  return (
    <>
      <div className="card-header">
        <div className="card-title row row-space-between">
          Edit creator options
        </div>
      </div>

      <div className="card-body">
        <div className="flex-1">
          <div className="m-b-4">
            <div className="m-b-1">
              <span className="text-light m-r-1">Network:</span>
              <span className="text-bold text-dark">
                {campaign.network.networkName}
              </span>
            </div>
            <div className="m-b-1">
              <span className="text-light m-r-1">Campaign:</span>
              <span className="text-bold text-dark">
                {campaign.campaign.campaignName}
              </span>
              <span className="m-l-1 text-light">
                ({trackerUuids.length} domain
                {trackerUuids.length === 1 ? '' : 's'})
              </span>
            </div>
          </div>

          <div className="m-b-2">
            <div className="control control-checkbox">
              <input
                type="checkbox"
                checked={creatorsOnly}
                onChange={(event) => setCreatorsOnly(event.target.checked)}
              />
              <label>Creators only</label>
            </div>
            <span
              data-tip="Only clicks from creators will be able to use this campaign"
              data-for="creator-tooltip"
              className="m-l-1"
            >
              <SVG src="/images/recommendations/icon-info.svg" />
            </span>
          </div>

          <div className="m-b-2">
            <div className="control control-checkbox">
              <input
                type="checkbox"
                checked={creatorIdsMethod === 'specific'}
                onChange={(event) =>
                  setCreatorIdsMethod(event.target.checked ? 'specific' : 'all')
                }
              />
              <label>Manual approval</label>
            </div>
            <span
              data-tip="Clicks from these creators will be prioritized for this campaign"
              data-for="creator-tooltip"
              className="m-l-1"
            >
              <SVG src="/images/recommendations/icon-info.svg" />
            </span>
          </div>

          <ReactTooltip
            id="creator-tooltip"
            type="dark"
            effect="solid"
            place="right"
          />

          <Collapse open={creatorIdsMethod === 'specific'} dynamic>
            <div>
              <div className="row">
                <div className="flex-1 control w-100">
                  <input
                    className="w-100"
                    placeholder="Add creator IDs (comma separated)"
                    value={newCreatorId}
                    onChange={(event) => setNewCreatorId(event.target.value)}
                    onKeyDown={(event) => {
                      if (event.key === 'Enter') {
                        addCreatorIds()
                      }
                    }}
                  />
                </div>
                <button className="btn btn-primary" onClick={addCreatorIds}>
                  <SVG src="/images/icon-plus2-color.svg" />
                </button>
              </div>
              <div className="m-t-2">
                {creatorIds.map((creatorId: string) => (
                  <span key={creatorId} className="chip m-t-1 m-r-1">
                    <span>{creatorId}</span>
                    <button
                      className="chip-btn"
                      onClick={() => {
                        removeCreatorId(creatorId)
                      }}
                    >
                      <SVG
                        width={14}
                        height={14}
                        className="rotate-45"
                        src="/images/icon-plus2-color.svg"
                      />
                    </button>
                  </span>
                ))}
              </div>
            </div>
          </Collapse>
        </div>
        <div className="row row-space-between row-center row-nowrap m-t-4">
          <div className="text-red max-w-50">{error}</div>
          <div className="row">
            {initialCreatorsOnly || initialCreatorIds?.length ? (
              <button
                className="btn btn-bordered"
                disabled={submitting}
                onClick={onDisable}
              >
                Remove
              </button>
            ) : null}

            <button
              className="btn btn-dark"
              disabled={submitting}
              onClick={onSubmit}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

// const DisableSplitTestModal = (props) => {
//   const { advertiserUuid, hideModal, reloadCampaign, showSnackbar } = props
//
//   const onSubmit = async () => {
//     await deleteRequest(`/advs/v1/camps/split`, {
//       advertiserUuid,
//     })
//
//     showSnackbar('Traffic split removed')
//     reloadCampaign()
//     hideModal()
//   }
//
//   return (
//     <>
//       <div className="card-header">
//         <div className="card-title row row-space-between">
//           Remove split test
//         </div>
//       </div>
//       <div className="card-body">
//         <div>Are you sure you want to remove split test for this merchant?</div>
//         <div className="row row-end m-t-5">
//           <button className="btn btn-dark" onClick={onSubmit}>
//             Remove
//           </button>
//         </div>
//       </div>
//     </>
//   )
// }

const MultiSelectDropdown = (props) => {
  const {
    label,
    values,
    sortKey,
    uuidGetter,
    labelGetter,
    selectedValues,
    toggleSelectedValue,
    clearSelectedValues,
  } = props

  const [open, setOpen] = useState(false)
  const ref = useRef()
  useOutsideClick(ref, () => {
    setOpen(false)
  })

  const [search, setSearch] = useState('')
  const showSearch = values.length > 5

  const options = useMemo(
    () =>
      sortBy(values, sortKey).map((value) => ({
        value: uuidGetter(value),
        label: labelGetter(value),
      })),
    [values]
  )
  const shownOptions = useMemo(() => {
    if (search) {
      return options.filter(
        (option) =>
          option.label.toLowerCase().indexOf(search.toLowerCase()) !== -1
      )
    } else {
      return options
    }
  }, [options, search])

  return (
    <div className="subnav-filter w-100" ref={ref}>
      <button
        className={varClass({
          'filter-button text-primary m-r-15 merchant-filter-button': true,
          'active': open,
        })}
        onClick={() => setOpen(!open)}
      >
        <>
          {label}
          {': '}
          <b className="m-l-1">
            {selectedValues.length === 1
              ? options.find((option) => option.value === selectedValues[0])
                  .label
              : selectedValues.length > 1
              ? `${selectedValues.length} ${label.toLowerCase()} selected`
              : 'All'}
          </b>
        </>
        <SVG
          src="/images/chevron-down.svg"
          className={varClass({
            'm-l-1': true,
            'rotate-180': open,
          })}
        />
      </button>
      <div
        className={varClass({
          'submenu w-100 options': true,
          'shown': open,
        })}
      >
        {showSearch && (
          <div className="control control-focusless">
            <SVG src="/images/icon-search.svg" />
            <input
              type="text"
              placeholder={`Find ${label.toLowerCase().replace(/s$/, '')}`}
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
          </div>
        )}
        <ul>
          {map(shownOptions, (option) => (
            <li
              key={option.value}
              title={toUnicode(option.label)}
              className={varClass({
                'selected': selectedValues.includes(option.value),
              })}
              onClick={() => {
                toggleSelectedValue(option.value)
              }}
            >
              {toUnicode(option.label)}
            </li>
          ))}
          {isBlank(shownOptions) && (
            <li className="text-light text-bold text-center inactive">
              Nothing found
            </li>
          )}
        </ul>
        {selectedValues.length > 0 && (
          <button
            className="remove-btn link"
            onClick={() => {
              clearSelectedValues()
            }}
          >
            <SVG src="/images/icon-trash.svg" className="m-r-2" />
            Clear
          </button>
        )}
      </div>
    </div>
  )
}
