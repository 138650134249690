import moment from 'moment'
import { filterBySearch, isBlank, newTableSortExt } from '../../shared'
import { availableGroupBy, defaultGroupBy, groupFilterKeys } from './constants'

export const normalizeGroupBy = (
  value: string,
  datePickerState?: any
): string => {
  if (value === null) {
    return normalizeGroupBy(window.location.hash.substr(1) || '')
  } else if (
    value === 'date' &&
    moment(datePickerState.range.startDate).format('YYYY-MM-DD') ===
      moment(datePickerState.range.endDate).format('YYYY-MM-DD')
  ) {
    return 'hour'
  } else if (availableGroupBy.includes(value)) {
    return value
  } else {
    return defaultGroupBy
  }
}

export const normalizeFilter = (opts: {
  datePickerState: any
  filters: any
  origin?: string
  trafficFilter?: boolean
  usePrevDates?: boolean
}) => {
  const { datePickerState, filters, origin, trafficFilter } = opts
  const usePrevDates = opts.usePrevDates ?? false
  const result: any = {
    fromDate: moment
      .utc(
        usePrevDates
          ? datePickerState.prevRange.startDate
          : datePickerState.range.startDate
      )
      .add(1, 'day')
      .startOf('day'),
    toDate: moment
      .utc(
        usePrevDates
          ? datePickerState.prevRange.endDate
          : datePickerState.range.endDate
      )
      .endOf('day'),
    filters: {},
  }

  for (const filter of filters) {
    const key = groupFilterKeys[filter.key] || filter.key
    if (filter.value) {
      switch (filter.key) {
        case 'date':
          {
            const selectedDate = new Date(filter.value)
            result.fromDate = moment.utc(selectedDate).startOf('day')
            result.toDate = moment.utc(selectedDate).endOf('day')
          }
          break
        case 'month':
          {
            const selectedMonth = new Date(filter.value)
            result.fromDate = moment.utc(selectedMonth).startOf('month')
            result.toDate = moment.utc(selectedMonth).endOf('month')
          }
          break
        case 'year':
          {
            const selectedYear = new Date(filter.value)
            result.fromDate = moment.utc(selectedYear).startOf('year')
            result.toDate = moment.utc(selectedYear).endOf('year')
          }
          break
        case 'tags':
          {
            const [tagKey, tagValue] = filter.value.split(' = ')
            if (tagKey && tagValue) {
              result.filters.tags = {
                [tagKey]: tagValue,
              }
            }
          }
          break
        case 'networkUuids':
          {
            if (filter.value === 'Unknown') {
              result.filters[key] = ''
            } else {
              result.filters[key] = filter.value.split(', ')
              const allIndex = result.filters[key].indexOf('all')
              if (allIndex !== -1) result.filters[key].splice(allIndex, 1)
            }
          }
          break
        default:
          {
            if (filter.value === 'Unknown') {
              result.filters[key] = ''
            } else {
              result.filters[key] = filter.value.split(', ')
            }
          }
          break
      }
    }
  }

  if (origin) {
    result.filters.origin = origin
  }

  if (trafficFilter) {
    result.filters.withPartialTraffic = true
  }

  return result
}

export const statisticsSearch = (collection: any, search: string) => {
  const excludeUnknownRow = collection?.filter(
    (row) => (row.grp || row.name) && row.name.toLowerCase() !== 'unknown'
  )
  return filterBySearch(excludeUnknownRow, search, (row: any) => [
    row.grp,
    row.name,
    'Unknown',
  ])
}

export const statisticsCustomFilter = (opts: {
  data: any
  prevData?: any
  search: string
  favorites: any
  group: string
  crFilter?: boolean
  ecrFilter?: boolean
  favFilter?: boolean
}) => {
  const {
    data: collection,
    prevData: prevCollection,
    search,
    group,
    crFilter,
    ecrFilter,
    favFilter,
  } = opts
  const favorites = (opts.favorites || []).filter(
    (favorite) => favorite.favoriteGroupBy === group
  )

  let result = collection

  if (crFilter || ecrFilter || favFilter) {
    result = collection.filter((row) => {
      let crMatch = false
      let ecrMatch = false
      let favMatch = false
      if (crFilter) {
        crMatch =
          (row.grp || row.name) &&
          row.name.toLowerCase() !== 'unknown' &&
          row.clickCount &&
          (row.conversionCount / row.clickCount) * 100 <= 0.8
      }
      if (ecrFilter) {
        const prev =
          row.prev ||
          prevCollection?.find(
            (prevRow) =>
              (row.grp && row.grp == prevRow.grp) ||
              (row.name && row.name == prevRow.name)
          )
        if (prev && prev.clickCount >= 500) {
          const ecr =
            (row.conversionTotalCommission / (row.conversionSale || 1)) * 100
          const prevEcr =
            (prev.conversionTotalCommission / (prev.conversionSale || 1)) * 100
          const ecrDiff = (ecr / prevEcr) * 100
          ecrMatch = ecrDiff <= 85
        }
      }
      if (favFilter) {
        favMatch = !!favorites.find(
          (favorite) =>
            favorite.favoriteGroupName === row.grp ||
            favorite.favoriteGroupName === row.name
        )
      }
      return crMatch || ecrMatch || favMatch
    })
  }

  if (search) {
    return statisticsSearch(result, search)
  } else {
    return result
  }
}

export const statisticsSort = newTableSortExt((a: any, b: any, key) => {
  if (key === 'name') {
    return [
      a.name.toLowerCase() || a.grp.toLowerCase() || 'unknown',
      b.name.toLowerCase() || b.grp.toLowerCase() || 'unknown',
    ]
  }
  if (key === 'ctr') {
    return [
      a.pageviewCount > 0 ? a.clickCount / a.pageviewCount : 0,
      b.pageviewCount > 0 ? b.clickCount / b.pageviewCount : 0,
    ]
  }
  if (key === 'cr') {
    return [
      a.clickCount > 0 ? a.conversionCount / a.clickCount : 0,
      b.clickCount > 0 ? b.conversionCount / b.clickCount : 0,
    ]
  }
  if (key === 'rr') {
    return [
      a.rejectedConversionCount /
        (a.conversionCount + a.rejectedConversionCount || 1),
      b.rejectedConversionCount /
        (b.conversionCount + b.rejectedConversionCount || 1),
    ]
  }
  if (key === 'epc') {
    return [
      a.clickCount > 0 ? a.conversionTotalCommission / a.clickCount : 0,
      b.clickCount > 0 ? b.conversionTotalCommission / b.clickCount : 0,
    ]
  }
  if (key === 'epm') {
    return [
      a.pageviewCount > 0
        ? a.conversionTotalCommission / (a.pageviewCount / 1000)
        : 0,
      b.pageviewCount > 0
        ? b.conversionTotalCommission / (b.pageviewCount / 1000)
        : 0,
    ]
  }
  if (key === 'ecr') {
    return [
      a.conversionSale > 0
        ? (a.conversionTotalCommission / a.conversionSale) * 100
        : 0,
      b.conversionSale > 0
        ? (b.conversionTotalCommission / b.conversionSale) * 100
        : 0,
    ]
  }
  if (key === 'rejectedCount') {
    return [a.rejectedConversionCount, b.rejectedConversionCount]
  }
  if (key === 'grossSale') {
    return [
      a.conversionSale + a.conversionRejectedSale,
      b.conversionSale + b.conversionRejectedSale,
    ]
  }
  if (key === 'grossConversionCount') {
    return [
      a.conversionCount + a.rejectedConversionCount,
      b.conversionCount + b.rejectedConversionCount,
    ]
  }
})

export const statisticsPaginate = (collection: any, limit: number) => {
  return collection.slice(0, limit)
}

export const findFavorite = (
  favorites: any,
  group: string,
  name1: string = '',
  name2: string = ''
) => {
  return favorites?.find(
    (favorite) =>
      favorite.favoriteGroupBy === group &&
      ((name1 && favorite.favoriteGroupName === name1) ||
        (name2 && favorite.favoriteGroupName === name2))
  )
}

export const formatFilterDates = (datePickerState) => {
  const result = {}
  const date = moment(datePickerState.range.endDate)

  while (date.isAfter(datePickerState.range.startDate)) {
    const value = date.format('YYYY-MM-DD')
    result[value] = value
    date.subtract(1, 'day')
  }

  return result
}

export const sumRows = (rows: any) => {
  const result = {
    conversionCount: 0,
    rejectedConversionCount: 0,
    conversionCommission: 0,
    rejectedConversionCommission: 0,
    cpcCommission: 0,
    rejectedCpcCommission: 0,
    conversionClickCommission: 0,
    conversionTotalCommission: 0,
    conversionPendingCommission: 0,
    conversionRejectedCommission: 0,
    conversionSale: 0,
    conversionRejectedSale: 0,
    clickCount: 0,
    pageviewCount: 0,
    prev: undefined,
  }
  if (isBlank(rows)) return result
  for (const row of rows) {
    for (const column in row) {
      result[column] = result[column] || 0
      if (typeof row[column] === 'number') {
        result[column] += row[column]
      }
      if (row.prev) {
        result.prev = result.prev || {}
        result.prev[column] = result.prev[column] || 0
        if (typeof row[column] === 'number') {
          result.prev[column] += row[column]
        }
      }
    }
  }
  return result
}
