import React, { useEffect, useMemo, useRef, useState } from 'react'
import SVG from 'react-inlinesvg'
import { uniqBy } from 'lodash'
import { Placeholder } from './Placeholder'
import {
  TTimeout,
  ICountriesV1List,
  Commission,
  countrySort,
  formatCommission,
  formatFilterRecords,
  formatStatNumberByCurrency,
  Header,
  isBlank,
  Loadable,
  map,
  NavFilter,
  navigateTo,
  parseQuery,
  Search,
  SmallLoadable,
  TableEmpty,
  Tooltip,
  useApiGet,
  useApiPost,
  useApiMore,
  useModal,
  useSnackbar,
  useSortableTable,
  useTableEmpty,
  varClass,
  WithTooltip,
} from '../shared'
import { getCurrentCurrency } from '../../services'
import { NewLinkModal } from '../tools/shortener'
import ReactTooltip from 'react-tooltip'
import { DndProvider, useDrag, useDragLayer, useDrop } from 'react-dnd'
import { getEmptyImage, HTML5Backend } from 'react-dnd-html5-backend'

const isProduction = process.env.GATSBY_ENV === 'production'

const stateStorageKey = 'merchants.state'
const perPage = 50

const filterLabels = {
  // 'statuses': 'Status',
  'campaignSettings': 'Campaign settings',
  'trackerNetworkUuids': 'Network',
  'trackerCountryCodes': 'Country',
  // 'hasOpportunities': 'Recommended',
}

const filterOptions = {
  // 'statuses': {
  //   'approved': 'Approved',
  //   'notApproved': 'Not approved',
  //   'inactive': 'Inactive',
  // },
  'campaignSettings': {
    'prioritised': 'Prioritised',
    'disabled': 'Disabled',
    'closed': 'Closed',
    'trafficSplit': 'Split tests',
    'creators': 'Creators only',
    // 'trafficPartial': 'Partial traffic',
  },
  // 'hasOpportunities': {
  //   'opportunities': 'New',
  //   'alternatives': 'Alternatives',
  // },
}

const defaultTableColumns = [
  'advertiserDomain',
  'commission',
  'country',
  // 'status',
  'campaignSettings',
  'actions',
]

const tableLabels = {
  'advertiserDomain': 'Merchants',
  'commission': 'Commission (up to)',
  // 'status': 'Status',
  'country': 'Country',
  'campaignSettings': 'Campaign settings',
  'actions': 'Actions',
}

const allowSortingColumns = [
  'advertiserDomain',
  'commission',
  'country',
  // 'status',
  // 'campaignSettings',
]

const normalizeFilters = (filters) => {
  const result: { [key: string]: any } = {}
  result.trackerIsActive = true
  for (const filter of filters) {
    if (filter.value) {
      const splitFilterValue = filter.value.split(', ')
      switch (filter.key) {
        case 'statuses':
          {
            const hasApproved = splitFilterValue.some((v) => v === 'approved')
            const hasNotApproved = splitFilterValue.some(
              (v) => v === 'notApproved'
            )
            const hasInactive = splitFilterValue.some((v) => v === 'inactive')
            if (hasApproved && hasNotApproved && hasInactive) {
              break
            }
            if (hasInactive) {
              result.trackerIsActive = true
              result.hasTraffic = false
            }
            if (hasApproved && !hasNotApproved) {
              result.trackerIsActive = true
              result.hasTraffic = true
            }
            if (!hasApproved && hasNotApproved) {
              result.trackerIsActive = false
            }
          }
          break
        case 'campaignSettings':
          {
            const isPrioritised = splitFilterValue.some(
              (v) => v === 'prioritised'
            )
            const isDisabled = splitFilterValue.some((v) => v === 'disabled')
            const isClosed = splitFilterValue.some((v) => v === 'closed')
            const isSplit = splitFilterValue.some((v) => v === 'trafficSplit')
            const isPartial = splitFilterValue.some(
              (v) => v === 'trafficPartial'
            )
            const isCreatorOnly = splitFilterValue.some((v) => v === 'creators')
            if (isPrioritised) {
              result.trackerIsPriority = true
            }
            if (isDisabled) {
              result.trackerIsExcluded = true
            }
            if (isClosed) {
              result.trackerIsRejected = true
            }
            if (isSplit) {
              result.trackerIsSplitTraffic = true
            }
            if (isPartial) {
              result.trackerIsPartialTraffic = true
            }
            if (isCreatorOnly) {
              result.trackerIsCreatorOnly = true
            }
          }
          break
        case 'hasOpportunities':
          result[filter.key] = filter.value
          break
        default:
          result[filter.key] = [...splitFilterValue]
          break
      }
    }
  }
  return result
}

const normalizeSort = (sort) => {
  switch (sort) {
    case 'advertiserDomain':
      return 'advertiserDomain'
    case 'commission':
      return 'GREATEST(tracker_commission_percentage, tracker_commission_flat)'
    case 'country':
      return 'trackerCountryCode'
    case 'status':
      return 'trackerIsActive'
    case 'campaignSettings':
      return 'trackerIsPriority'
    default:
      return 'GREATEST(tracker_commission_percentage, tracker_commission_flat)'
  }
}

export const Merchants2 = (props) => {
  const restoredState = useMemo(() => {
    const savedState = sessionStorage.getItem(stateStorageKey)
    if (!savedState) return {}
    try {
      return JSON.parse(savedState)
    } catch (err) {
      console.log(err)
      return {}
    }
  }, [])

  const initialQuery = useMemo(() => parseQuery(location.search), [])

  const [filters, setFilters] = useState(() =>
    restoredState.filters
      ? restoredState.filters
      : [
          // { key: 'statuses', value: '' },
          { key: 'trackerNetworkUuids', value: initialQuery.networkUuid || '' },
          { key: 'trackerCountryCodes', value: initialQuery.country || '' },
          { key: 'campaignSettings', value: '' },
          // { key: 'hasOpportunities', value: '' },
        ]
  )
  const filterReset = useMemo(
    () =>
      filters
        .filter((filter) => filter.value)
        .map((filter) => filter.value)
        .join(','),
    [filters]
  )

  const [columns, setColumns] = useState(() => defaultTableColumns)

  const [draggedColumn, setDraggedColumn] = useState('')

  const swapColumns = (value1: string, value2: string) => {
    const index1 = columns.indexOf(value1)
    const index2 = columns.indexOf(value2) || 1
    if (index1 === index2) return
    if (index1 === 0 || index2 === 0) return
    const newColumns = [...columns]
    const item = newColumns[index1]
    newColumns.splice(index1, 1)
    newColumns.splice(index2, 0, item)
    setColumns(newColumns)
    // localStorage.setItem(tableColumnsKey, newColumns.join(','))
  }

  const defaultSort = columns.includes('commission')
    ? 'commission'
    : 'advertiserDomain'
  const { sort, dir, toggleSort } = useSortableTable({
    sort: restoredState.sort || defaultSort,
    dir: restoredState.dir || 'desc',
  })

  const [search, setSearch] = useState(() => restoredState.search || '')

  const {
    data: mainData,
    total,
    loaded,
    loading,
    hasMore,
    loadMore,
  } = useApiMore<any>(
    '/advs/v3.1/camps',
    (offset: number) => ({
      page: Math.floor(offset / perPage),
      perPage: perPage,
      search: search
        .toLowerCase()
        .replace(/https?:\/\//, '')
        .replace(/^www\./, '')
        .replace(/[/?#].*$/, ''),
      orderBy: normalizeSort(sort),
      orderDirection: dir,
      // hasOpportunities: 'any',
      // trackerIsActive: true,
      ...normalizeFilters(filters),
    }),
    {
      perPage,
      resetDeps: [search, filterReset, sort, dir],
      dataKey: 'campaigns',
      totalKey: 'totals',
    }
  )

  useEffect(() => {
    sessionStorage.setItem(
      stateStorageKey,
      JSON.stringify({
        filters,
        search,
        sort,
        dir,
      })
    )
  }, [search, filterReset, sort, dir])

  // const totalsData = useApiGet('/advs/v3/camps/totals')

  const data = useMemo(() => {
    const mergedData = [...(mainData || [])]
    const uniqData = uniqBy(
      mergedData,
      (item) => item.advertiser.advertiserUuid
    )
    return uniqData
  }, [mainData])

  const networks = useApiGet('/networks/v1/list')

  const countries = useApiGet<ICountriesV1List[]>('/countries/v1/list')
  const sortedCountries = useMemo(
    () => countries && countries.sort(countrySort),
    [countries]
  )

  return (
    <>
      <Header label="Merchants" />
      <Loadable data={loaded} placeholder={<Placeholder />}>
        <DndProvider backend={HTML5Backend}>
          <Content
            {...{
              initialQuery,
              data,
              total,
              // totalsData,
              loading,
              hasMore,
              loadMore,
              columns,
              setColumns,
              swapColumns,
              draggedColumn,
              setDraggedColumn,
              search,
              setSearch,
              sort,
              dir,
              toggleSort,
              filters,
              setFilters,
              networks,
              countries: sortedCountries,
            }}
          />
        </DndProvider>
      </Loadable>
    </>
  )
}

const Content = (props) => {
  const {
    initialQuery,
    data,
    total,
    // totalsData,
    loading,
    hasMore,
    loadMore,
    columns,
    setColumns,
    swapColumns,
    draggedColumn,
    setDraggedColumn,
    search,
    setSearch,
    sort,
    dir,
    toggleSort,
    filters,
    setFilters,
    networks,
    countries,
  } = props

  return (
    <div className="card merchants-card">
      <div className="text-primary text-bold m-b-4">
        Discover campaigns from{' '}
        <span className="text-new-blue">
          {(total?.all || 0).toLocaleString()}
        </span>{' '}
        merchants
      </div>

      <Nav
        {...{
          search,
          setSearch,
          filters,
          setFilters,
          networks,
          countries,
        }}
      />

      <Table
        {...{
          campaigns: data,
          campaignsTotal: total,
          loading,
          hasMore,
          loadMore,
          columns,
          setColumns,
          swapColumns,
          draggedColumn,
          setDraggedColumn,
          search,
          setSearch,
          sort,
          dir,
          toggleSort,
          initialQuery,
          countries,
        }}
      />
    </div>
  )
}

const Nav = (props) => {
  const { search, setSearch, filters, setFilters, networks, countries } = props
  const { showModal } = useModal()
  const { showSnackbar } = useSnackbar()

  const [shownSearch, setShownSearch] = useState(search)

  const onSelect = (key, value, label) => {
    const newFilters = [...filters]
    const filter = newFilters.find((filter) => filter.key === key)
    if (
      key !== 'hasOpportunities' &&
      key !== 'statuses' &&
      key !== 'campaignSettings'
    ) {
      if (filter.value) {
        const splitValue = filter.value.split(', ')
        const splitLabel = filter.label.split(', ')
        const existValue = splitValue.find((v) => v === value)
        if (existValue) {
          filter.value = splitValue.filter((v) => v !== value).join(', ')
          filter.label = splitLabel.filter((l) => l !== label).join(', ')
        } else {
          filter.value += `, ${value}`
          filter.label += `, ${label}`
        }
      } else {
        filter.value = value
        filter.label = label
      }

      if (!value || label === 'All') {
        filter.label = label
        filter.value = value
      }
    } else {
      filter.value = value
      filter.label = label
      if (filter.value === '') filter.label = ''
    }
    setFilters(newFilters)
  }

  const onRemove = (_, key) => {
    const newFilters = filters.map((item) => {
      if (item.key === key) {
        return { ...item, value: '', label: '' }
      }
      return { ...item }
    })
    setFilters(newFilters)
  }

  return (
    <div className="page-nav m-b-4">
      <div className="row m-b-4">
        <div className="flex-1 vertical-middle">
          <Search
            value={shownSearch}
            setValue={setShownSearch}
            onSubmit={() => {
              setSearch(shownSearch)
            }}
            placeholder="Search for merchants"
            className="search w-60"
            inputClassName="w-100 p-r-8 merchant-search-input"
            highlight={!!shownSearch}
          />
          <button
            className={varClass({
              'link link-new-lighter': true,
              'o-0 unclickable': !shownSearch,
              'o-100': shownSearch,
            })}
            style={{
              position: 'relative',
              top: 2,
              left: -32,
              paddingTop: 4,
              marginTop: -4,
            }}
            onClick={() => {
              setShownSearch('')
              setSearch('')
            }}
          >
            <SVG src="/images/icon-times.svg" />
          </button>
          <button
            className="btn btn-white btn-merchant-search m-l--6"
            onClick={() => {
              setSearch(shownSearch)
            }}
          >
            Search
          </button>
        </div>
        <button
          className="btn btn-primary"
          onClick={() => {
            showModal(
              <NewLinkModal
                onDone={() => {
                  navigateTo('/tools/urls')
                }}
                showSnackbar={showSnackbar}
              />
            )
          }}
        >
          Create smartlink
        </button>
      </div>
      <NavFilter
        {...{
          filters,
          setFilters,
          filterLabels,
          filterOptions: {
            ...filterOptions,
            trackerNetworkUuids: formatFilterRecords(
              networks,
              'networkUuid',
              'networkName'
            ),
            trackerCountryCodes: formatFilterRecords(
              countries,
              'countryCode',
              'countryName'
            ),
          },
        }}
        onRemove={onRemove}
        onSelect={onSelect}
        hideNoValue
        hideAdd
        isMultipleChoice
      />
    </div>
  )
}

// const NavTabs = (props) => {
//   const currencyCode = getCurrentCurrency()
//   const { tab, tabs, setTab } = props
//
//   return (
//     <div className="page-subnav">
//       <div className="nav p-y-2 m-x--5 m-t-0 m-b-1">
//         <ul>
//           {map(tabs, (item) =>
//             item.value === 'all' || item.count ? (
//               <li
//                 key={item.value}
//                 className={tab === item.value ? 'active' : ''}
//               >
//                 <button
//                   className="link vertical-middle"
//                   onClick={() => setTab(item.value)}
//                 >
//                   {!!item?.icon && (
//                     <SVG
//                       src={item.icon}
//                       width={18}
//                       height={18}
//                       className="m-r-1"
//                     />
//                   )}
//                   {item.label}
//                   {item.count && (
//                     <div className="merchant-count">
//                       {formatStatNumberByCurrency(item.count, currencyCode)}
//                     </div>
//                   )}
//                 </button>
//               </li>
//             ) : null
//           )}
//         </ul>
//       </div>
//     </div>
//   )
// }

const Table = (props) => {
  const {
    campaigns,
    loading,
    hasMore,
    loadMore,
    columns,
    swapColumns,
    draggedColumn,
    setDraggedColumn,
    search,
    setSearch,
    sort,
    dir,
    toggleSort,
    countries,
  } = props

  const [shownSearch, setShownSearch] = useState(search)
  const searchRef = useRef<TTimeout>()

  useEffect(() => {
    if (searchRef.current) {
      clearTimeout(searchRef.current)
    }
    searchRef.current = setTimeout(() => {
      setSearch(shownSearch)
      searchRef.current = undefined
    }, 650)
  }, [shownSearch])

  const [isEmpty, setIsEmpty] = useTableEmpty(campaigns)

  const handleScroll = (event) => {
    if (hasMore && !loading) {
      if (
        event.target.scrollHeight - event.target.scrollTop <=
        event.target.clientHeight + 30
      ) {
        loadMore()
      }
    }
  }

  const itemProps = {
    sort,
    columns,
    draggedColumn,
    countries,
  }

  return (
    <div className="m-t-50">
      <div
        className="table-container-overflow background-white"
        onScroll={handleScroll}
      >
        <table className="table table-bordered table-sortable table-hoverable advertisers-table">
          <thead>
            <tr>
              {columns.map((column, index) => {
                const allowSorting = allowSortingColumns.some(
                  (c) => c === column
                )
                return (
                  <TableHead
                    key={column}
                    index={index}
                    value={column}
                    label={tableLabels[column]}
                    desc={column === 'commission'}
                    {...{
                      toggleSort,
                      sort,
                      dir,
                      columns,
                      draggedColumn,
                      setDraggedColumn,
                      swapColumns,
                      allowSorting,
                    }}
                  />
                )
              })}
            </tr>
          </thead>
          <tbody className="text-nowrap">
            {map(campaigns, (campaign, index) => (
              <TableItem key={index} campaign={campaign} {...itemProps} />
            ))}
            {!isEmpty && !loading && isBlank(campaigns) && (
              <tr>
                <td colSpan={10} className="text-center text-light text-bolder">
                  Nothing found
                </td>
              </tr>
            )}
            {loading && (
              <tr>
                <td colSpan={10} className="text-center text-light text-bolder">
                  <div className="m-t-15 m-b-2">
                    <SmallLoadable loaded={false} />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <DragTable
          {...{
            campaigns,
          }}
        />

        {isEmpty && (
          <TableEmpty
            icon="advertisers"
            title="No Merchant data available yet"
            subtitle="To see your merchants simply connect a network"
            buttons={{
              '/networks': 'Connect network',
            }}
            setIsEmpty={setIsEmpty}
            style={{ inset: -15, borderRadius: 6 }}
          />
        )}

        <Tooltip
          id="advertiser-alternative-tooltip"
          type="dark"
          effect="solid"
          place="bottom"
          rebuild={[campaigns]}
        />

        <ReactTooltip
          type="dark"
          effect="solid"
          place="bottom"
          className="text-left"
          html={true}
        />
      </div>
    </div>
  )
}

const TableHead = (props) => {
  const {
    value,
    label,
    desc,
    sort,
    dir,
    toggleSort,
    index,
    columns,
    draggedColumn,
    setDraggedColumn,
    swapColumns,
    allowSorting,
  } = props

  const ref = useRef(null)

  const [{ isDragging }, dragHandle, drag] = useDrag(() => ({
    type: 'column',
    item: () => {
      setDraggedColumn(value)
      return { value }
    },
    end: (item) => {
      setDraggedColumn('')
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  const [_opts, drop] = useDrop({
    accept: 'column',
    hover: (item, monitor) => {
      if (!ref.current) {
        return
      }
      const value1 = item.value
      const value2 = value
      if (value1 === value2) {
        return
      }
      const index1 = columns.indexOf(value1)
      const index2 = columns.indexOf(value2)

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      const hoverQuarterX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 4

      const clientOffset = monitor.getClientOffset()
      const hoverClientX = clientOffset.x - hoverBoundingRect.left

      if (index1 < index2 && hoverClientX < hoverMiddleX - hoverQuarterX) {
        return
      }
      if (index1 > index2 && hoverClientX > hoverMiddleX + hoverQuarterX) {
        return
      }

      swapColumns(value1, value2)
    },
    drop: (item: any) => {
      swapColumns(item.value, value)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  })

  drag(getEmptyImage())
  drop(ref)

  const narrowColumn = value === 'campaignSettings' || value === 'actions'

  if (index === 0) {
    return (
      <th
        onClick={() => {
          if (allowSorting) {
            toggleSort(value, desc ? 'desc' : 'asc').call(this)
          }
        }}
        ref={ref}
        className={varClass({
          'vertical-middle p-r-4 w-30': true,
          'sort-highlight': sort === value,
        })}
      >
        {draggedColumn && (
          <div
            className={varClass({
              'drop-space': true,
              'opaque': draggedColumn === columns[index],
            })}
          />
        )}
        <div className="row row-fill row-nowrap">
          <span className="column-label">
            {label}
            {allowSorting ? (
              sort === value ? (
                <>
                  {dir === 'desc' ? (
                    <SVG
                      src="/images/insights/caret-down.svg"
                      className="m-l-1"
                    />
                  ) : (
                    <SVG
                      src="/images/insights/caret-up.svg"
                      className="m-l-1"
                    />
                  )}
                </>
              ) : (
                <SVG src="/images/insights/caret.svg" className="m-l-1" />
              )
            ) : null}
          </span>
        </div>
      </th>
    )
  } else {
    return (
      <th
        onClick={() => {
          if (allowSorting) {
            toggleSort(value, desc ? 'desc' : 'asc').call(this)
          }
        }}
        ref={ref}
        className={varClass({
          'relative vertical-middle p-l-2 p-r-4': true,
          'w-10': narrowColumn,
          'w-20': !narrowColumn,
          'sort-highlight': sort === value,
          'drag-highlight': draggedColumn === value,
        })}
        style={{
          color: isDragging ? 'transparent' : '',
        }}
      >
        {draggedColumn && <div className="drop-space" />}
        <div className="row row-fill row-nowrap">
          <span ref={dragHandle} className="cursor-grab m-r-10">
            <SVG src="/images/insights/icon-drag.svg" className="handle" />
          </span>
          <span className="column-label">
            {label}
            {allowSorting ? (
              sort === value ? (
                <>
                  {dir === 'desc' ? (
                    <SVG
                      src="/images/insights/caret-down.svg"
                      className="m-l-1"
                    />
                  ) : (
                    <SVG
                      src="/images/insights/caret-up.svg"
                      className="m-l-1"
                    />
                  )}
                </>
              ) : (
                <SVG src="/images/insights/caret.svg" className="m-l-1" />
              )
            ) : null}
          </span>
        </div>
      </th>
    )
  }
}

const TableItem = (props) => {
  const { campaign, sort, columns, draggedColumn, countries } = props

  const values = useMemo(() => {
    const numberOfCountries = campaign.campaignCountryCodes.length
    let country = ''
    if (numberOfCountries) {
      if (numberOfCountries === 1) {
        const existedCountry = countries?.find(
          (c) => c.countryCode === campaign.campaignCountryCodes[0]
        )
        country = existedCountry?.countryName
      } else {
        country = `Multiple (${numberOfCountries})`
      }
    }
    return {
      advertiserDomain: campaign.advertiser.advertiserDomain,
      commission: formatCommission(
        campaign.campaignComission || campaign.campaignCommission,
        campaign.campaignCommissionFlat,
        campaign.campaignCommissionPercentage,
        campaign.campaignCurrencyCode || 'DKK',
        true
      ),
      country,
    }
  }, [campaign, countries])

  return (
    <>
      <tr>
        {columns.map((column) => (
          <td
            key={column}
            className={varClass({
              'text-nowrap': true,
              'text-right':
                column === 'campaignUpdatedDatetime' ||
                column === 'campaignRejectionRate',
              'p-x-4 p-y-3': column === 'campaignNetworksCount',
              'sort-highlight': sort === column,
              'drag-highlight': draggedColumn === column,
            })}
          >
            {(() => {
              switch (column) {
                case 'advertiserDomain':
                  return (
                    <button
                      className="name"
                      onClick={(event) => {
                        navigateTo(
                          event,
                          `/merchants/${campaign.advertiser.advertiserUuid}`
                        )
                      }}
                    >
                      {values[column]}
                      <SVG src="/images/icon-details.svg" className="arrow" />
                    </button>
                  )

                case 'commission':
                  return (
                    <>
                      <Commission
                        className="m--2"
                        type={
                          campaign.campaignComission ||
                          campaign.campaignCommission
                        }
                        value={values[column]}
                      />
                      {campaign.campaignOpportunity && (
                        <WithTooltip
                          className="advertiser-alternative inline-block m-l-4"
                          title="A new campaign can be found for this merchant."
                          text={
                            <SVG
                              src="/images/recommendations/icon-campaigns.svg"
                              width={18}
                              height={14}
                            />
                          }
                          tooltipId="advertiser-alternative-tooltip"
                          forceShow
                        />
                      )}
                      {campaign.campaignAlternative && (
                        <WithTooltip
                          className="advertiser-alternative inline-block m-l-4"
                          title="A campaign with higher commission can be found for this merchant."
                          text={
                            <SVG
                              src="/images/recommendations/icon-alt-campaigns.svg"
                              width={20}
                              height={14}
                            />
                          }
                          tooltipId="advertiser-alternative-tooltip"
                          forceShow
                        />
                      )}
                    </>
                  )

                case 'status':
                  return campaign.campaignIsActive ? (
                    campaign.hasTraffic ? (
                      <span className="p-x-2 border-round p-y-1 text-smaller text-center approved">
                        Approved
                      </span>
                    ) : (
                      <span className="p-x-2 border-round p-y-1 text-smaller text-center inactive">
                        Inactive
                      </span>
                    )
                  ) : (
                    <span className="p-x-2 border-round p-y-1 text-smaller text-center not-approved ">
                      Not Approved
                    </span>
                  )

                case 'campaignSettings':
                  return (
                    <div className="row row-center row-narrow row-nowrap">
                      {campaign.campaignIsRevshare ? (
                        <span className="badge badge-hoverable text-smaller text-nowrap m-05">
                          Heylink Boost
                        </span>
                      ) : null}
                      {campaign.campaignIsPriority ? (
                        <SVG
                          src="/images/advertisers/icon-priority.svg"
                          title="Prioritized"
                        />
                      ) : null}
                      {campaign.campaignIsExcluded ? (
                        <SVG
                          src="/images/advertisers/icon-excluded.svg"
                          title="Disabled"
                        />
                      ) : null}
                      {campaign.campaignIsCreatorOnly ? (
                        <SVG
                          src="/images/advertisers/icon-creators.svg"
                          title="Creators"
                        />
                      ) : null}
                    </div>
                  )

                case 'actions':
                  return (
                    <button
                      className="link text-small"
                      onClick={(event) => {
                        navigateTo(
                          event,
                          `/merchants/${campaign.advertiser.advertiserUuid}`
                        )
                      }}
                    >
                      View merchant
                    </button>
                  )

                default:
                  return values[column]
              }
            })()}
          </td>
        ))}
      </tr>
    </>
  )
}

const DragTable = (props) => {
  const { campaigns } = props

  const { item, currentOffset, isDragging } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }))

  if (!isDragging || !currentOffset) {
    return null
  }

  const columns = [item?.value]

  return (
    <div
      className="advertisers-card drag-card"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        transform: `translate(${currentOffset?.x}px, ${currentOffset?.y}px)`,
        pointerEvents: 'none',
        zIndex: 100,
      }}
    >
      <div className="table-container-overflow">
        <table
          className="table table-bordered table-sortable advertisers-table drag-table relative"
          style={{
            position: 'relative',
            width: 100,
            height: 100,
          }}
        >
          <thead className="text-nowrap">
            <tr>
              <th className="relative vertical-middle p-l-2 p-r-4 drag-highlight">
                <div className="row row-space-between row-nowrap">
                  <span className="cursor-grab text-blue m-r-10">
                    <SVG
                      src="/images/insights/icon-drag.svg"
                      className="handle"
                    />
                  </span>
                  <span className="column-label">
                    {tableLabels[item?.value]}
                    <SVG src="/images/insights/caret.svg" className="m-l-1" />
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="text-nowrap">
            {map(campaigns, (campaign) => (
              <DragTableItem
                key={campaign.advertiser.advertiserUuid}
                {...{
                  campaign,
                  columns,
                }}
              />
            ))}
            {isBlank(campaigns) && (
              <tr>
                <td colSpan={10} className="text-center text-light text-bolder">
                  Nothing found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

const DragTableItem = (props) => {
  const { campaign, columns } = props

  const values = {
    'advertiserDomain': campaign.advertiser.advertiserDomain,
    'commission': formatCommission(
      campaign.campaignComission || campaign.campaignCommission,
      campaign.campaignCommissionFlat,
      campaign.campaignCommissionPercentage,
      campaign.campaignCurrencyCode || 'DKK',
      true
    ),
  }

  const column = columns[0]

  return (
    <>
      <tr>
        <td
          className={varClass({
            'text-nowrap w-20': true,
            'text-right':
              column === 'campaignUpdatedDatetime' ||
              column === 'campaignRejectionRate',
            'p-x-4 p-y-3': column === 'campaignNetworksCount',
          })}
        >
          {(() => {
            switch (column) {
              case 'advertiserDomain':
                return (
                  <button
                    className="link text-blue text-bold"
                    onClick={(event) => {
                      navigateTo(
                        event,
                        `/merchants/${campaign.advertiser.advertiserUuid}`
                      )
                    }}
                  >
                    {values[column]}
                  </button>
                )
              case 'commission':
                return (
                  <Commission
                    className="m--2"
                    type={
                      campaign.campaignComission || campaign.campaignCommission
                    }
                    value={values[column]}
                  />
                )
              default:
                return values[column]
            }
          })()}
        </td>
      </tr>
    </>
  )
}
